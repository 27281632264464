import React,{useRef} from 'react'
import { Container, Form } from 'react-bootstrap'
import MailchimpSubscribe from "react-mailchimp-subscribe"
import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';

export default function Email() {
    init("user_VunEIO9kDbmWZSsFxLXC0");
    const form = useRef();
    function isBot(){

        const isFilledName= document.getElementById("eemail")
        const isFilledEmail = document.getElementById("naame")

        if( isFilledEmail !== null || isFilledName !== null ){
            const sendEmail = () => {
                emailjs.sendForm('service_g4rcroj', 'template_4gc7zvv', form.current, 'user_VunEIO9kDbmWZSsFxLXC0')
                  .then(() => {
                  }, () => {
                  });
              };
              return sendEmail()
        }
    }

    const url = "https://aiforexistence.us1.list-manage.com/subscribe/post?u=dcbf0dfb733d51f91d9084b87&amp;id=5fe2970b32";
    return (
        
        <div style={{
            marginTop:"160px"
        }}>
            <Container className="pb-4 justify-content-center align-items-center text-center "  >
                    <h2>Join to get special offers</h2>
                    <p className='banner-paragraph'>Follow our offers and start when you are ready.</p>
                    <Form className="d-flex align-items-center justify-content-center" id="email-content-wrapper " 
                    onSubmit={isBot}
                    ref={form}>
                        
                       <div className="email-form-wrapper">

                        <MailchimpSubscribe className="text-area" url={url}/>
                        
                        <label class="ohnohoney" for="naame"></label>
                        <input class="ohnohoney" autocomplete="off" type="text" id="naame" name="name" placeholder="Your name here" />
                        <label class="ohnohoney" for="eemail"></label>
                        <input class="ohnohoney" autocomplete="off" type="email" id="eemail" name="email" placeholder="Your e-mail here" />

                       </div>
                    </Form>
                
            </Container>
        </div>
    )
}
