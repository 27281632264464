import React from 'react';
import Navigation from '../../design/elements/Navbar';
import AboutUs from './components/AboutUs';
import Footer from './components/partials/Footer';
function About() {
    return (
        <div>
            <Navigation />
            <AboutUs></AboutUs>
            <Footer></Footer>
        </div>
    );
}

export default About;