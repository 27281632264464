import React, { useState, useEffect } from "react";
import { SidebarData } from "./SidebarData";
import AccountTreeRoundedIcon from "@mui/icons-material/AccountTreeRounded";
import BookRoundedIcon from "@mui/icons-material/BookRounded";
import logo from "../styles/images/logo.svg";
import { Tooltip } from "@mui/material";
import { Button, DropdownButton, Dropdown } from "react-bootstrap";
import { useAuth } from "../../context/AuthContext";
import "firebase/firestore";
import app from "../../firebase";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CreditCardRoundedIcon from "@mui/icons-material/CreditCardRounded";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';


function Sidebar() {
  const db = app.firestore();

  const { currentUser, logout } = useAuth();
  const [error, setError] = useState("");

  async function handleLogout() {
    setError("");
    try {
      await logout();
      navigator.push("/home");
    } catch (error) {
      setError("Failed to log out. Please try again");
    }
  }

  
  return (
    <div className="w-25 sidebar-custom-wrapper">
      <div className="sidebar-custom">
        {/* Taşı */}
        <div className="sidebar-logo-wrapper d-flex flex-column align-items-center justify-content-center">
          <img width={60} src={logo} />
        </div>
        <ul className="sidebar-inner">
          {SidebarData.map((value, key) => {
            return (
              <li
                key={key}
                className="item mb-2 bold-small-paragraph"
                id={window.location.pathname == value.link ? "active" : ""}
                onClick={() => {
                  window.location.pathname = value.link;
                }}
              >
                <div id="icon">{value.icon}</div>
                <div id="title">{value.title}</div>
              </li>
            );
          })}
          <li
            onClick={() =>
              window.open(
                "https://zapier.com/apps/brieferr/integrations",
                "_blank"
              )
            }
            className="item mb-2 bold-small-paragraph"
          >
            <div id="icon">
              <AccountTreeRoundedIcon />
            </div>
            <div id="title">Integrations</div>
          </li>
          <li
            onClick={() => window.open("https://blog.brieferr.com", "_blank")}
            className="item mb-2 bold-small-paragraph"
          >
            <div id="icon">
              <BookRoundedIcon />
            </div>
            <div id="title">Blog</div>
          </li>

          <li
            className="item mb-2 bold-small-paragraph"
            id={window.location.pathname == "/profile" ? "active" : ""}
            onClick={() => {
              window.location.pathname = "/profile";
            }}
          >
            <div id="icon">
              <AccountCircleRoundedIcon />
            </div>
            <div id="title">Profile</div>
          </li>
          <li
            className="item mb-2 bold-small-paragraph"
            id={window.location.pathname == "/plan-billing" ? "active" : ""}
            onClick={() => {
              window.location.pathname = "/plan-billing";
            }}
          >
            <div id="icon">
              <CreditCardRoundedIcon />
            </div>
            <div id="title">Plan & Billing</div>
          </li>
        </ul>{" "}
       
         
              <div className="sidebar-logo-wrapper d-flex flex-column align-items-center justify-content-center">
                <li
                  className="item mb-2 bold-small-paragraph"
                  onClick={handleLogout}
                >
                  <div id="icon">
                    <LogoutRoundedIcon />
                  </div>
                  <div id="title">Log Out</div>
                </li>
              </div>
          
      </div>
    </div>
  );
}

export default Sidebar;
