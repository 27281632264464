import React from 'react';
import Navigation from '../nav/Navbar';
import Dev from './components/images/dev.jpg'
import AnchorLink  from 'react-anchor-link-smooth-scroll'
import {Tab, Col, Row, Nav,} from 'react-bootstrap'
import Faq from './components/partials/Faq';
import Feedback from '../user/frontend/feedback/Feedback';
import {Helmet} from "react-helmet";

function Issues() {
    return (
        <div > 
            <Helmet>
        <title>Brieferr | Get Help - Known Issues </title>
        <meta name="description" content="Helmet application" />
    </Helmet>

            <Navigation></Navigation>
            <div className="d-flex flex-row " style={{gap:"2em"}}>

            <div className=" w-25 " id="side-nav">
            <div className=" d-flex flex-column align-items-between pt-5 justify-content-around"style={{position:"fixed",zIndex:"0"}} >
                <div >
                <AnchorLink style={{zIndex:1000}} href='#issues'>
                <a >1. Known Issues</a>
                </AnchorLink>
                </div>
                <div>
                <AnchorLink style={{zIndex:1000}} href='#faq'>
                <a >2. Frequently Asked Questions</a>
                </AnchorLink>
                </div>
               
            </div>
            </div>


            <div style={{width:"95%"}}>
            <div className="text-center d-flex flex-column align-items-center"> 
            <h1 className="pt-5 pb-5 " >Get Help</h1> 
            <p className="w-75 pb-5">We are on our <b>beta</b> stage now. We are continuously developing Brieferr and put into service. You can give us feedbacks and report issues in order to serve you better. </p>
            
          
            <div className="w-50 m-auto">
                <img src={Dev} style={{width:"100%"}}></img>
            </div>            
            </div>
            <div id="issues">
                <h2 className="pb-5 pt-5 text-center"> Known Issues</h2>
                <div className="mb-5 mt-5">
                <Tab.Container id="left-tabs-example"  defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="first">Empty Cells Error</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="second">Internet Connection Error</Nav.Link>
                        </Nav.Item>
                        
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                        <b>Empty Cells Error</b><br></br><br></br>

                        After you created your project you may encountered <b>Empty Cells</b> error.<br></br><br></br>
                        For our NLP algorithms to generate your dashboard automatically, all cells on your CSV file's <b>Questions section must be full.</b>
                        <br></br><br></br>
                        Try to upload your data after you fill all the empty cells in Questions and it should hopefully work.

                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                        <b>Internet Connection Error</b><br></br><br></br>

                        You may see this error if your <b>internet connection is bad</b> or <b>if your file is too big</b> to get response from the API. <br></br><br></br>
                        We can't generate your dashboard with more than 100 columns. <b>But we really want to!</b>
                        We are still working to improve our range of file size. We will solve this issue as soon as possible.
                        <br></br><br></br>
                        If your data has less than 100 columns please check your internet connection. If it is fast enough you will not get a timeout, and you will not encounter this error.
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>
                </div>
            </div>



{/* FAQ */}
            <div id="faq">
            <h2 className="pb-5 pt-5 text-center"> Frequently Asked Questions</h2>
                <div className="mb-5 mt-5">
                <Faq></Faq>
                </div>
            </div>
            </div>
            </div>
            
        </div>
    );
}

export default Issues;