import React, { useRef, useState } from "react";
import { Card, Form, Alert, Container } from "react-bootstrap";
import Button, {
  SecondaryButton,
  PrimaryButtonVariant,
} from "../../../design/components/PrimaryButton";
import { Link, useHistory, Redirect } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import "firebase/storage";
import "firebase/firestore";
import firebase from "firebase/app";
import { auth } from "../../../firebase";
import { Helmet } from "react-helmet";
import { init } from "emailjs-com";
import app from "../../../firebase";
import { TextInput, PasswordInput } from "../../../design/components/TextInput";

const googleProvider = new firebase.auth.GoogleAuthProvider();
init("user_VunEIO9kDbmWZSsFxLXC0");

const db = app.firestore();

export default function Signup() {
  const { currentUser } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  async function signInWithGoogle(e) {
    e.preventDefault();

    //GOOGLE SIGNIN
    try {
      setLoading(true);

      await auth.signInWithPopup(googleProvider);

      window.location.replace("/user-info");
    } catch (error) {
      setError("Failed to Log In with Google");
    }

    setLoading(false);
  }

  //EMAIL SIGNIN

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError("Passwords do not match");
      } else {
        setError("");
        setLoading(true);
        await signup(emailRef.current.value, passwordRef.current.value);
        window.location.replace("/user-info");
      }
    } catch (error) {
      setError("Failed to create an account");
    }

    setLoading(false);
  }

  return (
    <Container className="d-flex align-items-center justify-content-center signUpIn-container">
      <Helmet>
        <title>Brieferr | Sign Up</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className=" w-100">
        <Card.Body className=" w-100 ">
          <h2 className=" mb-4">
            Create a{" "}
            <span style={{ color: "var(--purple)" }}>Brieferr account</span>
          </h2>
          <p className="banner-paragraph" style={{ color: "var(--dark-gray)" }}>
            Turn your responses into insights, boost your customer success.
          </p>

          <Form
            id="signup-form"
            className="d-flex flex-column align-items-center"
            onSubmit={handleSubmit}
          >
            {error && <Alert variant="danger"> {error}</Alert>}

            <TextInput
              referance={emailRef}
              placeholder="Work Email"
              class="w-100 pt-5"
            />
            <div className="d-flex w-100 passwords pt-3 pb-3">
              <PasswordInput
                placeholder="Password"
                type="password"
                class="w-100"
                referance={passwordRef}
                required
              />

              <PasswordInput
                placeholder="Verify Password"
                class="w-100"
                referance={passwordConfirmRef}
                required
              />
            </div>

            <div className="w-100 pb-5">
              <PrimaryButtonVariant
                disabled={loading}
                text="Sign Up"
                typ="submit"
                id="form-group"
              ></PrimaryButtonVariant>
              <div className="pt-3">
                <SecondaryButton
                  disabled={loading}
                  click={signInWithGoogle}
                  id="form-group"
                  text="Continue With Google"
                  icon="https://img.icons8.com/color/24/000000/google-logo.png"
                ></SecondaryButton>
              </div>
            </div>
          </Form>

          <small
            style={{ color: "#707070" }}
            className="w-100 text-left pb-3 pt-3"
          >
            By clicking Sign Up or Continue With Google buttons, you agree to
            our{" "}
            <a href="/terms" target="_blank">
              Terms and Conditions
            </a>{" "}
            and that you read to our{" "}
            <a href="/data-policy" target="_blank">
              Data Use Policy
            </a>
            , including Cookie Use.
          </small>
          <div className="w-100 text-center mt-2">
            Already have an account? <Link to="login">Log In</Link>
          </div>
        </Card.Body>
      </div>
    </Container>
  );
}
