import { set } from 'nprogress';
import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';

function CookieAlert() {
    const cookie = localStorage.getItem("cookie")

    return (
        <>
        {cookie ? 
        <div></div>
            : 
            <div style={{
                position:"fixed",
                width:"100%",
                height: "max-content",
                
                padding: "0",
                margin: "0",
                left: "0",
                zIndex:"10",
                bottom:"0",
              }}
              >
                <Card style={{backgroundColor:"#333230e0",color:"var(--white)", borderRadius:"0"}}>
                    <Card.Body style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                        <span className="pb-3">
                            By continue to browse this website, you agree to use cookies to ensure you get the best user experience.
                        </span>
                        <div >
                        <button className="custom-btn" id="button" onClick={()=>{localStorage.setItem("cookie", true)
                    window.location.reload()}}>
                            <span>Got It</span>
                        </button>
                        </div>
                    </Card.Body>
                 </Card> 
                 </div> }
            
        </>
    );
}

export default CookieAlert;