import React from 'react';
import ContactForm from './components/ContactForm';
import Footer from './components/partials/Footer';


function Contact() {
    
    return (
        <div>
            <ContactForm></ContactForm>
            <Footer></Footer>
        </div>
    );
}

export default Contact;