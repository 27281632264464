import React , {useState, useEffect}from 'react';
import {Navbar, Nav, Container, NavDropdown} from 'react-bootstrap'
import logo from '../styles/images/logo.svg'
import arrow from '../styles/icons/chevron.svg'
import '../styles/css/Landing.css'
import PrimaryButton from '../components/PrimaryButton';
import { useAuth } from '../../context/AuthContext';
import 'firebase/firestore'
import app from '../../firebase'
function MobileSidebar() {
    const db = app.firestore()

    const { currentUser, logout } = useAuth();
    const [info, setInfo] = useState([]);
    const [error, setError] = useState("");

    async function handleLogout() {
        setError("");
        try {
          await logout();
          navigator.push("/home");
        } catch(error) {
          setError("Failed to log out. Please try again");
        }
      }
    
      useEffect(() => {
        const userName = async () => {
          const Users = await db
            .collection("Users")
            .where("userID", "==", currentUser.uid)
            .get();
          setInfo(
            Users.docs.map((doc) => {
              return doc.data();
            })
          );
        };
        userName();
      }, []);    
    return (
        <div className='mobile-side-wrapper'>
    <Navbar className='mobile-side' fixed="top"  collapseOnSelect expand="lg" variant="light">
  <Container>
  <Navbar.Brand href="/"><img src={logo}></img></Navbar.Brand>

  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
      <Nav.Link href="/">Dashboards</Nav.Link>
      <Nav.Link href="/pricing">Plans & Billing</Nav.Link>
      <Nav.Link href="/profile">Profile</Nav.Link>
      <Nav.Link onClick={handleLogout}>Log Out</Nav.Link>


    </Nav>
    <Nav className='nav-right'>
      
    </Nav>
  </Navbar.Collapse>

  </Container>
</Navbar>

        </div>
    );
}

export default MobileSidebar;