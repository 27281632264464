import React, {
  useEffect,
  useRef,
  useState,
  Redirect,
  useContext,
} from "react";
import { Card, Form, Button, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import { auth } from "../../../firebase";
import app from "../../../firebase";
import firebase from "firebase/app";
import { Helmet } from "react-helmet";
import {
  PrimaryButtonVariant,
  SecondaryButton,
} from "../../../design/components/PrimaryButton";
import { TextInput, PasswordInput } from "../../../design/components/TextInput";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { currentUser } = useAuth();

  const googleProvider = new firebase.auth.GoogleAuthProvider();

  const db = app.firestore();

  async function signInWithGoogle(e) {
    e.preventDefault();

    try {
      setLoading(true);
      await auth.signInWithPopup(googleProvider);
      window.location.replace("/");
    } catch (error) {
      setError("Failed to Log In with Google");
    }
    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      window.location.replace("/");
    } catch (error) {
      setError("Failed to Log In");
    }
    setLoading(false);
  }

  return (
    <Container className="d-flex signUpIn-container align-items-center justify-content-center h-100vw">
      <Helmet>
        <title>Brieferr | Log In</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className=" w-100">
        <Card.Body>
          <h2 className=" mb-4">Welcome Back to Brieferr</h2>
          <p className="banner-paragraph" style={{ color: "var(--dark-gray)" }}>
            Sign in to your acount.
          </p>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column align-items-center"
          >
            <Form.Group id="email" className="w-100" id="form-group">
              <TextInput
                referance={emailRef}
                placeholder="Work Email"
                class="w-100 pt-5"
              />
            </Form.Group>
            <Form.Group id="password" className="w-100" id="form-group">
              <PasswordInput
                placeholder="Password"
                type="password"
                class="w-100"
                referance={passwordRef}
                required
              />{" "}
            </Form.Group>

            <PrimaryButtonVariant
              disabled={loading}
              text="Log In"
            ></PrimaryButtonVariant>
          </Form>

          <div className="pt-3 pb-5">
            <SecondaryButton
              disabled={loading}
              click={signInWithGoogle}
              id="form-group"
              text="Continue With Google"
              icon="https://img.icons8.com/color/24/000000/google-logo.png"
            ></SecondaryButton>
          </div>
          <small
            style={{ color: "#707070" }}
            className="w-100 text-left pb-3 pt-3"
          >
            By clicking Continue With Google button, you agree to our{" "}
            <a href="/terms" target="_blank">
              Terms and Conditions
            </a>{" "}
            and that you read to our{" "}
            <a href="/data-policy" target="_blank">
              Data Use Policy
            </a>
            , including Cookie Use.
          </small>
          <div className="d-flex align-items-center justify-content-center pt-3"></div>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className="w-100 text-center mt-2">
            Don't have an account? <Link to="/signup">Sign up</Link>
          </div>
        </Card.Body>
      </div>
    </Container>
  );
}
