import React from 'react';

function DistanceSalesAgreement() {
    return (
        <div>
            <h1 id="-distance-sales-agreement-"><strong>Distance Sales Agreement</strong></h1>
<h3 id="-article-1-parties-"><strong>Article 1: PARTIES:</strong></h3>
<p>This Service Agreement (from now on referred to as the &quot;CONTRACT&quot;) Idealtepe, Dik <a href="http://sk.no:13/">Sk.No:13</a> Maltepe/İstanbul PK: 34841 AIX Artificial Intelligence Technologies Inc.(from now on referred to as &quot;the COMPANY&quot;) and on the <a href="http://brieferr.com/">Brieferr.com website</a>.BRIEFERR Data Analysis Platform (from now on referred to as &quot;PLATFORM&quot;), BRIEFERR Data Analysis Software (from now on referred to as &quot;SOFTWARE&quot;) and softwareVisitor to the <a href="http://brieferr.com/">Brieferr.com</a> website for the use of connected applications and services or a member of the platform (from now on referred to as &quot;CLIENT&quot;).</p>
<h3 id="-article-2-objective-"><strong>Article 2: OBJECTIVE:</strong></h3>
<p>The purpose of the contract is to regulate the commercial relationship between the COMPANY and the CUSTOMER, determine their rights and responsibilities.</p>
<h3 id="-article-3-subject-of-the-agreement-"><strong>Article 3: SUBJECT OF THE AGREEMENT:</strong></h3>
<p>AGREEMENT, PLATFORM terms of use, installation, and after-sales describe the services. With the acceptance of the AGREEMENT, the transactions to be made between the parties accepts that it will continue by the principles and provisions specified in the contract.</p>
<h3 id="-article-4-acceptance-of-the-agreement-"><strong>Article 4: ACCEPTANCE OF THE AGREEMENT:</strong></h3>
<p>AGREEMENT, registration for the PLATFORM test product or one of the paid packages &quot;I have read and accepted the terms and conditions &quot;It is deemed to be accepted by ticking the &quot;I agree&quot; box.</p>
<h3 id="-article-5-description-of-the-service-"><strong>Article 5: DESCRIPTION OF THE SERVICE:</strong></h3>
<p>A- With the PLATFORM offered by the COMPANY, the form data uploaded by the CUSTOMERS to the system will enable them to analyze their uploads.</p>
<p>B- The features and scope of the service PLATFORM <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=tr&amp;tl=en&amp;u=http://brieferr.com/">Brieferr.com</a> varies according to the packages specified on the site. CUSTOMER convenient will be entitled to use the PLATFORM by choosing the package and making the payment.</p>
<p>C- Project creation limits presented with the PLATFORM <a href="http://brieferr.com/">Brieferr.com</a> website differ according to the packages specified.</p>
<h3 id="-article-6-terms-of-use-and-duration-"><strong>Article 6: TERMS OF USE AND DURATION:</strong></h3>
<p>PLATFORM is software with all its functions specified for a certain period of <a href="http://time.it/">time. It</a> is a service offered, and its sectoral definition is known as SAAS MODEL. Licencestart date, cost of preferred package declared on <a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=tr&amp;tl=en&amp;u=http://brieferr.com/">Brieferr.com</a> web site with the payment; it starts with the acceptance of the submitted AGREEMENT and the license period is -1(One)- year. During this period, the CUSTOMER will be entitled to an additional fee offered within the scope of the PLATFORM. Will have the right to use any service that does not require PLATFORM license. Changing the package preference for which service is received in any way during the usage period. In case of a license, the license period will continue to be used on the basis of the first start date. If the CUSTOMER wishes to continue the service provided, he/she will be able to continue to use the information provided on the Brieferr.com website, which corresponds to the annual license, by paying the fee.</p>
<h3 id="-article-7-customer-obligations-"><strong>Article 7: CUSTOMER OBLIGATIONS:</strong></h3>
<p>For the paid or free PLATFORM that the CUSTOMER is using;</p>
<p>A- They will add their own products.</p>
<p>B- For all payment methods, they submit their applications to the relevant institutions with their commercial information.</p>
<p>C- Payment tracking, cargo tracking, breakdown requests, returns regarding future orders, the operation of additional transactions related to the requests, and similar order management will rule themselves.</p>
<p>D- They will not share user data with third parties, institutions, or organizations and voluntarily violate all security measures regarding the data if they share it. The COMPANY will not take any responsibility in such cases.</p>
<p>E- When the &quot;Delete My Account&quot; feature offered with the PLATFORM is used, all data will be permanently due to continuing the process despite a warning that it will be deleted. COMPANY accepts no responsibility for the deleted data any malfunctions that may occur.</p>
<p>F- In case of requesting support, the information requested to solve the problem logically, such as &quot;It happened spontaneously,&quot; &quot;It happened out of nowhere,&quot; to analyze the situation. They will not take an approach that is not possible; otherwise, although the COMPANYAlthough he followed the actions taken, he was not helped to find the source of the problem, and they accept that the responsibility does not belong to the COMPANY.</p>
<h3 id="-article-8-scope-of-support-"><strong>Article 8: SCOPE OF SUPPORT:</strong></h3>
<p>Issues that the COMPANY will support the CUSTOMER during the AGREEMENT;</p>
<p>A- The COMPANY provides support to the CUSTOMER regarding the use of the PLATFORM and agrees to guide problems.</p>
<p>B- The problem to be experienced is system-related or does not affect the sales operation of the CUSTOMER.In case of slowing down, the COMPANY has a written support system for intervening in the files. The right to make changes to files and data provided that special permission is obtained from. However, due to the problems that may occur as a result of this change, The COMPANY will not be held responsible.</p>
<p>C- CUSTOMER, to leave their requests to the internet support system in order to follow up their requests.can be redirected and the CUSTOMER will accept this redirection.</p>
<p>D- The COMPANY agrees to provide telephone support if needed. Support request according to the subject&#39;s qualifications, the COMPANY will use the CUSTOMER for other support for the solution of the problem, has the right to redirect to channels. Mutual correspondence on support requests is private and official institutions confidential by both the CUSTOMER and the COMPANY unless requested by the will be held.</p>
<h3 id="-article-9-software-ownership-"><strong>Article 9: SOFTWARE OWNERSHIP:</strong></h3>
<p>The SOFTWARE offered, designs, and all other additional services, paid or free, the property belongs to the COMPANY, and the CUSTOMER cannot claim any rights on them. The COMPANY has the right to make updates and changes regarding all these products, and it is accepted that these studies will not hinder the CUSTOMER&#39;s sales <a href="http://operation.it/">operation</a>.</p>
<h3 id="-article-10-force-majeure-"><strong>Article 10: FORCE MAJEURE:</strong></h3>
<p>Natural disasters, fire, government activities, national mobilization, riots, war or attempts at war, strike, lockout, etc., including but not limited provided that they do not exist and are not foreseen at the date of acceptance of the AGREEMENT, and that develops beyond the control of the parties, that one or each of the partiesthe debts and responsibilities of both of them with the CONTRACT partially or completely.situations that make it impossible for them to fulfill them or to fulfill them on time,will be considered as force majeure. If any of these reasons occur The obligations of the parties arising from this contract are suspended. This reason is 30 daysIf it continues for a period of time, either party may terminate the contract without compensation.may terminate. However, the rights and receivables of the parties accrued before the termination are reserved.will remain.</p>
<h3 id="-article-11-approval-and-effectiveness-"><strong>Article 11: APPROVAL AND EFFECTIVENESS:</strong></h3>
<p>This AGREEMENT, THE CUSTOMER Briefer<a href="https://translate.google.com/translate?hl=en&amp;prev=_t&amp;sl=tr&amp;tl=en&amp;u=http://brieferr.com/">.com</a> , for example, offered via the website becomes binding for both parties upon approval of its acceptance. CONTRACT Istanbul Courts and enforcement offices in case of disruptions that may arise from the application are authorized.</p>

        </div>
    );
}

export default DistanceSalesAgreement;