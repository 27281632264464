import React from 'react';
import { Link } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components'

const ButtonStyle = styled.button`
    font-size: 17px;
    color: ${props => props.theme.color};
    background-color: ${props => props.theme.bgColor};
    border: 1px solid ${props => props.theme.color};
    padding: .4em 1.5em;
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    gap: .5em;
    align-items: center;
    justify-content: center;
    width: 100%; 
    @media screen and (max-width: 430px){
        font-size: 15px;
    }
`
ButtonStyle.defaultProps = {
    theme: {
      color: "white",
      bgColor: "var(--purple)"
    }
  }
  const theme = {
    color: "var(--purple)",
    bgColor:"transparent"
  };

export default function PrimaryButton(props) {
    return (
   
            <Link className='w-100' to={props.link}>
            <ButtonStyle type="submit" href={props.href}>
                {props.text}
                <img src={props.icon} />
            </ButtonStyle>
            </Link>
      
    );
}
export function PrimaryButtonVariant(props){
    return(
        <ButtonStyle type="submit" href={props.href}>
        {props.text}
        <img src={props.icon} />
        </ButtonStyle>
    )

}

export function SecondaryButton(props) {
    return (
        <ThemeProvider  theme={theme}>
            <Link className='w-100' to={props.link}>
            <ButtonStyle onClick={props.click}>
                {props.text}
                <img src={props.icon} />
            </ButtonStyle>
            </Link>
        </ThemeProvider>
    );
}

