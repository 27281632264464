import React, { useRef, useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";

function ContactForm() {
  init("user_VunEIO9kDbmWZSsFxLXC0");
  const form = useRef();

  const [alert, setAlert] = useState();
  const [error, setError] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g4rcroj",
        "template_t4eg5qj",
        form.current,
        "user_VunEIO9kDbmWZSsFxLXC0"
      )
      .then(
        (result) => {
          setAlert("Your message succesfully delivered.");
        },
        (error) => {
          setError(
            "Uuups... Someting went wrong. Please try again or send us your message manually to 'hi@brieferr.com'"
          );
        }
      );
  };

  return (
    <div className="w-50 m-auto pt-5">
      <h1>Contact Us</h1>
      <p>Got any questions? Reach us.</p>

      <Form ref={form} onSubmit={sendEmail}>
        {error && <Alert variant="danger">{error}</Alert>}
        {alert && <Alert variant="success">{alert}</Alert>}
        <Form.Label className="mt-3" for="name">
          <b>Full Name</b>
        </Form.Label>
        <Form.Control
          type="text"
          name="name"
          id="name"
          placeholder="Please Enter Your Name"
          required
        />

        <Form.Label className="mt-3" for="company">
          <b>Company Name</b>
        </Form.Label>
        <Form.Control
          type="text"
          name="companyName"
          id="companyName"
          placeholder="Please Enter Your Email Adress"
          required
        />

        <Form.Label className="mt-3" for="email">
          <b>Email Adress</b>
        </Form.Label>
        <Form.Control
          type="email"
          name="email"
          id="email"
          placeholder="Please Enter Your Email Adress"
          required
        />

        <Form.Label for="message" className="mt-3">
          <b>Your Message</b>
        </Form.Label>
        <Form.Control
          as="textarea"
          id="message"
          name="message"
          placeholder="Please Enter Your Message"
          required
        />
        <button type="submit" className="custom-btn mt-3">
          Send Message
        </button>
      </Form>
    </div>
  );
}

export default ContactForm;
