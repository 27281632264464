import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled, { ThemeProvider } from "styled-components";
import "../styles/css/Dashboards.css";
import check from "../styles/icons/check.svg";
import { Accordion, Button } from "react-bootstrap";
import { PieChart, Pie, Tooltip, Cell } from "recharts";

const Card = styled.div`
  border-radius: 10px;
  border: 1px solid var(--soft-gray);
`;

const Body = styled.div`
  padding: 1em;
`;
const PlanCard = styled.div`
  border: none;
  box-shadow: ${(props) => props.theme.main};
  border-radius: 30px;
  min-height: 476px;
  max-width: 498px;
`;
const CardOverflow = styled.div`
  border-radius: 10px;
  border: 1px solid var(--soft-gray);
  max-height: 500px;
  height: 500px;
  overflow: scroll;
`;

const COLORS = ["#fc3003", "#FFBB28", "#00C49F"];

PlanCard.defaultProps = {
  theme: {
    main: "0px 4px 16px rgba(0, 0, 0, 0.25), 10px 10px 0px var(--soft-purple)",
  },
};
const orange = {
  main: "0px 4px 16px rgba(0, 0, 0, 0.25), 10px 10px 0px var(--soft-orange)",
};

function HighlightedSentences(props) {
  return (
    <Card>
      <Body>
        <p className="bold-small-paragraph">Highlighted Sentences</p>
        <ul>
          <li key="sentence1" className="purple-li">
            {props.sentence1}
          </li>
          <li key="sentence2" className="purple-li">
            {props.sentence2}
          </li>
        </ul>
      </Body>
    </Card>
  );
}

function SummaryQuestions(props) {
  const [search, setSearch] = useState("");

  return <div></div>;
}
function PlansCard(props) {
  return (
    <PlanCard>
      <Body className="p-4 pl-5 pr-5">
        <div className="text-center">
          <h3>{props.title}</h3>
          <hr style={{ color: "var(--gray)" }}></hr>
        </div>
        <p>{props.explantation}</p>
        {props.elements.map((element) => {
          return (
            <p>
              <img src={check}></img>
              {element}
            </p>
          );
        })}
      </Body>
    </PlanCard>
  );
}
function OrangePlans(props) {
  return (
    <ThemeProvider theme={orange}>
      <PlanCard>
        <Body className="p-4 pl-5 pr-5">
          <div className="text-center">
            <h3>{props.title}</h3>
            <hr style={{ color: "var(--gray)" }}></hr>
          </div>
          <p>{props.explantation}</p>
          {props.elements.map((element) => {
            return (
              <p>
                <img src={check}></img>
                {element}
              </p>
            );
          })}
        </Body>
      </PlanCard>
    </ThemeProvider>
  );
}
function DashboardCard(props) {
  return (
    <Card
      style={{
        minWidth: "420px",
      }}
    >
      <Body>
        <div className="d-flex flex-column">
          <a className="card-link-color" href={props.href}>
            <h3 className="title-card">{props.projectName}</h3>
            <p className="small-paragraph">Creation Date: {props.date}</p>
            <hr style={{ width: "100%" }}></hr>
            <p className="quick-insight-title">Quick Insights</p>
          </a>
        </div>
      </Body>
    </Card>
  );
}
function Ex() {
  return (
    <Container
      className="pt-4 d-flex"
      style={{
        gap: "2em",
        flexWrap: "wrap",
      }}
    ></Container>
  );
}

export {
  HighlightedSentences,
  DashboardCard,
  PlansCard,
  SummaryQuestions,
  Ex,
  OrangePlans,
  Card,
  Body,
};
