import React from "react";
import PrimaryButton from "./PrimaryButton";
import help from "../styles/icons/help.svg";
import dash from "../styles/icons/dashboard.svg";
import check from "../styles/icons/check.svg";

function DetailedPricingCard(props) {
  return (
    <div>
      <div className="pricing-card">
        <div className="pricing-card-body">
          <div className="d-flex ">
            <h3 className="gray-text">$</h3>
            <h2 className="price">{props.price}</h2>
            <h4 className="gray-text bold-small-paragraph">USD</h4>
          </div>
          <small>{props.small}</small>
          <hr className="pricing-hr"></hr>
          <h3 className="plan">{props.name}</h3>
          <p className="pricing-card-p">{props.cardP}</p>
          <div className="d-flex h-100 flex-column justify-content-end btn-wrapper">
            <button className="quota">{props.quota}</button>
            <PrimaryButton
              link={props.link}
              text={props.buttonText}
              className="pricing-btn"
            />
          </div>
        </div>
      </div>
      <div className="pt-5 pl-4 plan-features-special">
        <h6>{props.plan}</h6>
        <p>
          <img src={dash} />
          {props.dash}
        </p>
        <p>
          <img src={help} />
          {props.support}
        </p>

        <hr className="pricing-hr"></hr>

        {props.features.map((feature) => (
          <div className="check-mark">
            <img src={check} width={35} height={35}></img>
            {feature}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DetailedPricingCard;
