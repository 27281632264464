import React from 'react';
import Navigation from '../nav/Navbar';
import UserGuide from './UserGuide';
import {Helmet} from "react-helmet";

function UserGuideWorks(props) {
    return (
        <div>
            <Helmet>
        <title>Brieferr | How It Works - User Guide</title>
        <meta name="description" content="Helmet application" />
    </Helmet>

            <Navigation></Navigation>
            <UserGuide></UserGuide>
        </div>
    );
}

export default UserGuideWorks;