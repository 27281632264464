import {Redirect, Route} from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
import React from 'react'

export default function AdminRoute({ component: Component, ...rest}) {

    const {currentUser} = useAuth()

    return (
        <Route
            { ...rest }
            render={props => {
                return currentUser && currentUser.email === "info@aiforexistence.com" ? <Component {...props}/> : <Redirect to="/login"></Redirect>
            }}
            >
        </Route>
    )
}
