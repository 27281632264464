import React from "react";

const ForEnterprises = () => {
  return (
    <div style={{ maxWidth: "700px" }} className="pt-5 pb-5 text-center m-auto">
      <h2 className="pb-4">
        Get your company <span className="highlighted">on the right path</span>
        by focusing on what matters.
      </h2>
      <p className="banner-paragraph pb-4">
      We'll help you win by understanding longstanding employee's views on your company.
      </p>
      <div
        className="w-75 pb-4 m-auto d-flex 
            align-items-center justify-content-center"
        style={{ gap: "2em" }}
        id="use-case-last"
      >
        <a className="custom-btn" href="/signup">
          Start now
        </a>
        <p
          className="bold-small-paragraph"
          style={{ padding: "0", margin: "0", color: "var(--dark-gray)" }}
        >
          OR
        </p>
        <a className="custom-btn orange-btn" href="/live-demo">
          Try without signin
        </a>
      </div>
    </div>
  );
};

export default ForEnterprises;
