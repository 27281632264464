import React from "react";
import { Container } from "react-bootstrap";
import landing from "../styles/images/image (2).png";
import second from "../styles/images/image (3).png";
function Features() {
  return (
    <Container>
      <div
        className="d-flex flex-column"
        style={{ gap: "6em", marginBottom: "217px" }}
      >
        <div className="d-flex image-reverse justify-content-between align-items-center">
          <div className=" img-container">
            <img src={landing} style={{ width: "100%" }}></img>
          </div>
          <div className="text-wrapper img-container">
            <h2>See the main trends</h2>
            <p className="banner-paragraph">
              Important highlights, complaints and compliments, sentiments and
              popular words are all-in-one place.
            </p>
          </div>
        </div>

        <div className="d-flex image-column image-column justify-content-between align-items-center">
          <div className="text-wrapper left img-container ">
            <h2>Don’t miss outstanding responses</h2>
            <p className="banner-paragraph">
              You can read the most intriguing and see the person's contact
              information for later use.
            </p>
          </div>
          <div className="img-container">
            <img src={second} style={{ width: "100%" }}></img>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Features;
