import React from 'react';
import {Accordion, Card} from 'react-bootstrap'
import Ss from '../images/ss.png'
function Faq() {
    return (
        <div>
                       <Accordion defaultActiveKey="1" className="pb-5" flush>

      
    <Accordion.Toggle as={Card.Header} eventKey="0" >
      Can I add more questions on the dashboard?
    </Accordion.Toggle>
    
    <Accordion.Collapse eventKey="0">
        
      <Card.Body className="d-flex align-items-center">
          <div className="w-50">
        Yes, you can! <b>Click the plus button </b> on your importer when creating the project and you can add as much optional questions as you like. 
        </div>
        <div className="w-50">
            <img style={{width:"100%"}} src={Ss}></img>
        </div>
            
      </Card.Body>
      
    </Accordion.Collapse>
    
    
    
    
  
</Accordion>

        </div>
    );
}

export default Faq;