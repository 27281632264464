import React, { useState } from 'react';
import {Modal} from 'react-bootstrap'
import { CSVLink } from 'react-csv';
import { data } from './SampleCSV';
function DownloadCSV() {
    const [open, setOpen] = useState(true)
    return (
        <div>
          <Modal
          show={open}        
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Try with a sample data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       
          Hey, great to see you here! 
          <br></br>
          <br></br>This is your first project and, you might want to try with a <b>sample data</b> to understand how the importer works.
        </Modal.Body>
        <Modal.Footer className='d-flex flex-nowrap'>
          <button className='secondary-btn'style={{width:"50%"}} onClick={()=>{setOpen(false)}}>Upload Your Own CSV</button>
          <CSVLink  data={data}
          filename={"brieferr-sample.csv"}
          onClick={()=>setOpen(false)}
          className='custom-btn' style={{width:"50%"}} href="/">Download Sample</CSVLink>
        </Modal.Footer>
      </Modal>
        </div>
    );
}

export default DownloadCSV;