import React ,{ useRef, useState } from 'react'
import { Card, Form, Button, Alert,Container }from "react-bootstrap"
import { useAuth } from '../../../context/AuthContext'
import {Link} from 'react-router-dom'
import {Helmet} from "react-helmet";

export default function ForgotPassword() {
    const emailRef = useRef()
    const { resetPassword } = useAuth()
    const [error, setError] = useState(false)
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)
    
  
    async function handleSubmit(e) {
        e.preventDefault()
    
        try {
          setMessage("")
          setError("")
          setLoading(true)
          await resetPassword(emailRef.current.value)
          setMessage("Check your inbox for further instructions")
        } catch(error) {
          setError("Failed to reset password")
        }
    
        setLoading(false)
      }
    
    return (
        <Container className="d-flex align-items-center justify-content-center h-100vw">
            <Helmet>
        <title>Brieferr | Forgot Password</title>
        <meta name="description" content="Helmet application" />
    </Helmet>
        <div className=" w-100">
            <Card.Body className=" w-100">
            <h2 className="text-center mb-4">Password Reset</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            {message && <Alert variant="success">{message}</Alert>}
            
        <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
       
            <Form.Group id="form-group"className="w-50">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" ref={emailRef} required/>
            </Form.Group>
            
           
            <button disabled={loading}
            className="w-50 custom-btn" type="submit"
            id="form-group">
                Forgot Password
            </button>
        </Form>
        <div className="w-100 text-center mt-3">
            Already have an account? <Link to="/login">Log In</Link>
        </div>
        <div className="w-100 text-center mt-2">
            Don't have an account? <Link to="/signup">Sign up</Link>
        </div>
        </Card.Body>
        </div>
    </Container>
    )
}
