import React, { useRef, useState } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { TextInput } from '../../design/components/TextInput';
import Navigation from '../../design/elements/Navbar'
import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';


function LiveDemo(){
  init("user_VunEIO9kDbmWZSsFxLXC0");
  const form = useRef();
  const [repsonse, setResponse] = useState()
  const [email, setEmail] = useState()
  const [success, setSuccess] = useState()


 function onsubmit(){
  setResponse(document.getElementById("text") && document.getElementById("text").value)
  setEmail(document.getElementById("email") && document.getElementById("email").value)

    emailjs.sendForm('service_g4rcroj', 'template_5625r2d', form.current, 'user_VunEIO9kDbmWZSsFxLXC0')
    .then((result) => {
        setSuccess("We got your responses! We will email you the analytics. Stay tuned.")
    }, (error) => {
    });
  
 }


 const CopyPaste = () =>{
    return(
      <>
    {success && <Alert className='text-center' variant='success'>{success}</Alert>}  
       <p className='banner-paragraph text-center pb-5' >Copy and paste your open ended responses and quickly get your results. We will email you your dashboard!</p>
      <form ref={form} onSubmit={onsubmit}>
      <textarea
        id="text"
        placeholder='Copy & Paste your responses here.'
        rows={10} 
        name='response'
        className='text-area mb-3'
        style={{width:"100%"}} required/>
        <input
        className='text-area'
         style={{
          width: "100%"
        }}
        type='email' name='email' id="email" placeholder="Work email" required></input>
        <div className='pt-3 d-flex justify-content-end'>
          <div style={{
            width:"max-content",
          }}>
            <button type='submit'
            className='custom-btn'
            >
              Analyze
            </button>
          </div>
        </div> 
        </form>
       
       
      </>
    )
  }

        return (
          <>
         <Navigation/>
         <Container>
           <h2 className='text-center pt-5 pb-3'>Try <span className='highlighted'>without signing up</span></h2>
          
          <CopyPaste />
         </Container>
            </>
        );
    
}

export default LiveDemo;