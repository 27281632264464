import React from "react";
import { Container } from "react-bootstrap";
import ImageAndText from "../../components/ImageAndText";
import Navigation from "../../elements/Navbar";
import arrow from "../../styles/icons/chevron.svg";
import landing from "../../styles/images/image (2).png";
import cc from "../../styles/images/cc.png";
import metrics from "../../styles/images/metrics.png";
import UseCaseLast from "../../elements/UseCaseLast";
import Footer from "../../../components/page/components/partials/Footer";
import word from "../../styles/images/word.png";
import ForFounder from "./call to action footer/ForFounders";
function ForFounders(props) {
  return (
    <div>
      <Navigation />

      <Container>
        <div style={{ maxWidth: "900px" }} className="pt-5 text-center m-auto">
          <h1>
          Helping startups streamline their development  <br></br>
            <span className="highlighted">roadmap</span>
          </h1>
          <p className="pt-4 pb-4 banner-paragraph">
          Discover what matters to your users.
          </p>
          <div className="w-25 pb-4 m-auto" id="about-last">
            <a className="custom-btn mt-3" href="/signup">
              Start now <img src={arrow}></img>
            </a>
          </div>
          <div className="w-100">
            <img style={{ width: "100%" }} src={landing}></img>
          </div>
        </div>
      </Container>
      <div className="gray-bg mt-5 pt-5 pb-5">
        <Container>
          <ImageAndText
            heading="A platform that eliminates the uncertainty in product roadmap by generating highly actionable insights"
            smallText="COMPLIMENTS & COMPLAINTS"
            image={cc}
            flex="normal"
          />
        </Container>
      </div>
      <Container className="mt-5 mb-5 pt-5 pb-5">
        <ImageAndText
          heading="Plan product releases, prioritize features and sprint faster"
          smallText="METRICS"
          image={metrics}
          flex="reverse"
        />
      </Container>
      <div className="gray-bg mt-5 pt-5 pb-5">
        <Container>
          <ImageAndText
            heading="Identify the trends"
            smallText="DASHBOARD"
            paragraph="Effortlessly analyze open-ended responses to get actionable insights. See all the analytics on your dashboard without needing to read a single line of feedback."
            image={word}
            flex="normal"
          />
        </Container>
      </div>
      <ForFounder />
      <Footer />
    </div>
  );
}

export default ForFounders;
