import React from 'react';
import { Container } from 'react-bootstrap';
import ImageAndText from '../../components/ImageAndText';
import Navigation from '../../elements/Navbar';
import arrow from '../../styles/icons/chevron.svg'
import landing from '../../styles/images/image (2).png'
import cc from '../../styles/images/cc.png'
import metrics from '../../styles/images/metrics.png'
import ForEnterprise from './call to action footer/ForEnterprises';
import Footer from '../../../components/page/components/partials/Footer'
function ForEnterprises(props) {
    return (
        <div>
            <Navigation />

            <Container>
                <div 
                style={{maxWidth:"900px"}}
                className='pt-5 text-center m-auto'>
                    <h1>Helping enterprises retain their <br></br><span className='highlighted'> employees</span></h1>
                    <p className='pt-4 pb-4 banner-paragraph'> 
                    Discover what matters to your employees
                    </p>
                    <div className='w-25 pb-4 m-auto' id='about-last'>
                        <a  className='custom-btn mt-3' href='/signup'>Start now <img src={arrow}></img></a>
                    </div>
                    <div className='w-100'>
                        <img 
                        style={{width:"100%"}}
                        src={landing}></img>
                    </div>
                </div>
            </Container>
            <div className='gray-bg mt-5 pt-5 pb-5'>
                <Container>
                    <ImageAndText 
                    heading="A platform that increases employee engagement by generating highly actionable insights"
                    smallText="COMPLIMENTS & COMPLAINTS"
                    image={cc}
                    flex="normal" />
                </Container>
            </div>
            <Container className='mt-5 mb-5 pt-5 pb-5'>
                    <ImageAndText 
                    heading="Plan employee benefits, influence culture, and engage better"
                    smallText="METRICS"
                    image={metrics}
                    flex="reverse" />
            </Container>
            <div className='gray-bg mt-5 pt-5 pb-5'>
                <Container>
                    <ImageAndText 
                    heading="Read few, learn more"
                    smallText="DASHBOARD"
                    paragraph="Effortlessly analyze open-ended responses to get actionable insights. See all the analytics on your dashboard without needing to read a single line of feedback."
                    image={landing}
                    flex="normal" />
                </Container>
            </div>
            <ForEnterprise />
            <Footer />
        </div>
    );
}

export default ForEnterprises;