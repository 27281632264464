import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Honeybadger from '@honeybadger-io/js'
import ErrorBoundary from '@honeybadger-io/react'

Honeybadger.configure({
  apiKey: 'hbp_4IzQEtphFM8JljYPD0mH1IFqoIQRrB0RqqEw',
  environment: 'production'
  })
  
  

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
