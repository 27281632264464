import React from 'react';
import {Container} from 'react-bootstrap'
import form from '../styles/images/image (1).png'
import mobile from '../styles/images/image.png'
function FormApplications() {
    return (
        <div 
        className='form-apps'
        style={{
            padding: "5em",
            background: "var(--gray)",
            
        }}>
            <Container style={{display: 'flex',
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center"}}>
            <div style={{
                maxWidth:"790px",
                
            }} className='text-center'>
                <h2 className='pb-5'>Use <span className='highlighted'>any application</span>to get your responses</h2>
                <p className='banner-paragraph'>Brieferr is flexible for any for form creation tool you’d like to use. You can upload the form responses as a CSV file and generate your dasboard.</p>
            </div> 
            <div className=' mobile-form w-100'>
                <img src={mobile} width="100%"></img>
            </div>
            <div className='w-100 dektop-form'>
                <img src={form} width="100%"/>
            </div>
            </Container>
        </div>
    );
}

export default FormApplications;