import React from "react";
import { Container } from "react-bootstrap";
import Footer from "../../components/page/components/partials/Footer";
import Faq from "../../components/user/frontend/feedback/Faq";
import Navigation from "../elements/Navbar";

function Faqs() {
  return (
    <div>
      <Navigation />
      <Container>
          <h1 className="text-center p-5"><span className="highlighted">Frequently</span><br></br>Asked Questions</h1>
        <Faq
          question="What is Brieferr, how is it useful?"
          mainText="Brieferr is a feedback/survey/ticket analysis
      platform converting long interview answers "
          highlightedText="into data points. "
          continueMainText="Review insights in your 
      one-page dashboard to give the best decisions 
      for customer success."
        />

        <Faq
          question="How do I get started with Brieferr?"
          mainText="See our "
          link="https://blog.brieferr.com/get-started-with-brieferr/"
          linkText="starting guide here."
        />

        <Faq
          question="Which analysis does Brieferr do?"
          mainText="We use NLP techniquesto give you 
      the best results. "
          highlightedText="Sentiment analysis "
          continueMainText="for overall 
      satisfaction, Entity analysis for word cloud, Summary 
      analysis for summary generation and extraction, 
      and a combination of these for detecting 
      Compliment and Complaints."
        />

        <Faq
          question="Can Brieferr analyze all my existing forms, or do the documents have to be created on your site?"
          mainText="We can analyze any existing forms; you need to "
          highlightedText="upload them as a CSV file. "
          continueMainText="Also, our Zapier and Typeform integrations are about to launch."
        />

        <Faq
          question="Can I automate Briferr workflow?"
          mainText="Yes, you can connect any form platform with Brieferr using "
          link="https://zapier.com/apps/brieferr/integrations"
          linkText="Zapier Integrations."
        />

        <Faq
          question="Can I cancel or upgrade my plan?"
          mainText="Yes, you can cancel your plan anytime; you will be able to use the service until the end of the billing period."
        />
        <Faq
          question="What is included in Free Plan?"
          mainText="You can create one dashboard and analyze one-time surveys with the free plan. You will need a Pro plan for integrations and an additional dashboard. "
          link="https://www.brieferr.com/pricing"
          linkText="See Pricing page."
        />

        <Faq
          question="Can Brieferr be used for B2C surveys?"
          mainText="Yes, Brieferr can be used for B2C or B2B surveys."
        />

        <Faq
          question="How do you use cookies?"
          mainText="We use cookies to optimize the user interface by collecting analytics to develop the handiest UI."
        />

        <Faq
          question="How can I reset my account password?"
          mainText="Go to  "
          link="https://www.brieferr.com/forgot-password"
          linkText="Forgot Password Page "
          continueMainText="and follow the instructions."
        />
      </Container>
      <Footer />
    </div>
  );
}

export default Faqs;
