import React, { useState, useRef } from "react";
import Navigation from "../elements/Navbar";
import Footer from "../../components/page/components/partials/Footer";
import { Container, Alert, Form, Button } from "react-bootstrap";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
function Help() {
  init("user_VunEIO9kDbmWZSsFxLXC0");
  const form = useRef();

  const [alert, setAlert] = useState();
  const [error, setError] = useState();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g4rcroj",
        "template_t4eg5qj",
        form.current,
        "user_VunEIO9kDbmWZSsFxLXC0"
      )
      .then(
        (result) => {
          setAlert("Your message succesfully delivered.");
        },
        (error) => {
          setError(
            "Uuups... Someting went wrong. Please try again or send us your message manually to 'hi@brieferr.com'"
          );
        }
      );
  };

  return (
    <div>
      <Navigation />
      <Container>
        <h1 className="text-center p-5">
          <span className="highlighted">Help</span>Center
        </h1>
        <div className="d-flex p-3" style={{ gap: "2em" }}>
          <div className="w-50">
            <a
              id="help-card"
              href="https://blog.brieferr.com/get-started-with-brieferr/"
              target="_blank"
            >
              <h4 style={{ color: "black" }}>Get Started With Brieferr</h4>
              <p>
                You can analyze feedback forms or surveys and get helpful
                insight from your data with Brieferr. Here is a step-by-step
                guide to creating your first dashboard...
              </p>
            </a>
          </div>
          <div className="w-50">
            <a
              id="help-card"
              href="https://blog.brieferr.com/automate-brieferr-with-zapier/"
              target="_blank"
            >
              <h4 style={{ color: "black" }}>Automate Brieferr with Zapier</h4>
              <p>
                You can now automate our Brieferr workflows with Zapier
                automation. Check our Brieferr's Zapier page to get started. ⚠️
                You will need your dashboard...
              </p>
            </a>
          </div>
        </div>
      </Container>

      <div
        style={{
          backgroundColor: "var(--gray)",
          width: "100%",
          margin: "0",
          padding: "0",
        }}
        className="mt-5"
      >
        <Container className="pt-4 pb-4 text-center">
          <h3>Get Help From FAQs 👇</h3>
          <p>See the frequently asked questions.</p>
          <div className="w-25 m-auto" id="about-last">
            <a className="custom-btn mt-3 mb-3" href="/faq">
              Go to FAQs
            </a>
          </div>
        </Container>
      </div>

      <Container className="pt-4 pb-4 text-center">
        <div className="w-50 m-auto pt-5">
          <h3>Contact Us</h3>
          <p>Got any questions? Reach us.</p>

          <Form ref={form} onSubmit={sendEmail}>
            {error && <Alert variant="danger">{error}</Alert>}
            {alert && <Alert variant="success">{alert}</Alert>}
            <Form.Control
              type="text"
              name="name"
              id="name"
              className="appsumo-email mb-3"
              placeholder="Your Name"
              required
            />

            <Form.Control
              type="text"
              name="companyName"
              id="companyName"
              className="appsumo-email mb-3"
              placeholder="Work Email Adress"
              required
            />

            <Form.Control
              type="email"
              name="email"
              className="appsumo-email mb-3"
              id="email"
              placeholder="Please Enter Your Email Adress"
              required
            />

            <Form.Control
              as="textarea"
              id="message"
              name="message"
              className="appsumo-email mb-3"
              placeholder="Please Enter Your Message"
              required
            />
            <div className="w-50 m-auto" id="about-last">
              <button type="submit" className="custom-btn mt-3">
                Send Message
              </button>
            </div>
          </Form>
        </div>
      </Container>
      <Container className="p-3 pt-5 pb-5 mt-5 " id="building-brieferr">
        <h2 className="text-center pb-4">Use Cases</h2>
        <div
          className="d-flex text-center"
          style={{ gap: "2em" }}
          id="use-case-container"
        >
          <div
            style={{ width: "100%", minHeight: "max-content" }}
            className="pricing-card pricing-card-body"
          >
            <a id="help-card" href="/use-cases/for-enterprises">
              <h4>For Enterprises</h4>
              <p>
                Helping startups streamline their development roadmap Discover
                what matters to your users.
              </p>
            </a>
          </div>
          <div
            style={{ width: "100%", minHeight: "max-content" }}
            className="pricing-card pricing-card-body"
          >
            <a id="help-card" href="/use-cases/for-founders">
              <h4>For Startups</h4>
              <p>
                Helping enterprises retain their employees Discover what matters
                to your employees.
              </p>
            </a>
          </div>
          <div
            style={{ width: "100%", minHeight: "max-content" }}
            className="pricing-card pricing-card-body"
          >
            <a id="help-card" href="/use-cases/for-developers">
              <h4>For Campaign Managers</h4>
              <p>
                Helping campaign managers adapt their platform Discover what
                matters to your voters.{" "}
              </p>
            </a>
          </div>
          <div style={{width:"100%", minHeight:"max-content"}}className='pricing-card pricing-card-body'>
            <a id="help-card" href="/use-cases/for-developers">
              <h4>Retailers</h4>
              <p>
              Helping retailers identify upselling opportunities
              Discover what matters to your shoppers.
              </p>
            </a>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default Help;
