import React from "react";
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

export const SidebarData = [
    {
       title: "Dashboards",
       icon: <DashboardRoundedIcon></DashboardRoundedIcon>,
       link:"/"
    },
    {
        title: "New Dashboard",
        icon: <AddRoundedIcon />,
        link:"/new-project"
     }
]