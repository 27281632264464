import React, { useEffect, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import "firebase/storage";
import "firebase/firestore";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
// import ReactLoading from 'react-loading';
import { TextInput } from "../components/TextInput";
import firebase from "firebase/app";
import app from "../../firebase";
import "../styles/css/Importer.css";
import Sidebar from "../elements/Sidebar";
import ReactLoading from "react-loading";
import { Alert, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import sad from "../styles/images/sad.svg";
import "react-csv-importer/dist/index.css";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { purple } from "@mui/material/colors";
import DownloadCSV from "../components/DownloadCSV";
import { CSVLink } from "react-csv";
import { data } from "../components/SampleCSV";
import Joyride from "react-joyride";
function CreateDashboard() {
  //SETTING THE NAME
  const db = app.firestore();
  const [projectName, setProjectName] = useState("");
  const [error, setError] = useState();
  const { currentUser } = useAuth();
  const [projectNameSet, setProjectNameSet] = useState(false);
  const [noProjectName, setNoProjectName] = useState(false);
  const [columnName, setColumnName] = useState("Question 2");
  const [counter, setCounter] = useState(3);
  var storage = firebase.storage();
  let finalCsv = [];
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [errorPage, setErrorPage] = useState(false);
  const [projectWarn, setProjectWarn] = useState(true);
  const [isRun, setIsRun] = useState(false);

  var steps = [
    {
      target: ".texti",
      content: "You can give your project a name here.",
    },
    {
      target: ".importer",
      content: "Drag and drop the file you want to upload, then set columns.",
    },
    {
      target: ".add-col",
      content:
        "If you need more columns you can always add by clicking this plus sign.",
    },
  ];
  var callCount = 0;
  const CustomTooltip = ({
    skipProps,
    primaryProps,
    tooltipProps,
    isLastStep,
  }) => (
    <div {...tooltipProps}>
      <button {...skipProps}>Skip Tour</button>
      <button {...primaryProps}>{isLastStep ? "Close" : "Next"}</button>
    </div>
  );

  const LoadingAnim = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        top: "0",
        left: "0",
        position: "fixed",
        backgroundColor: "#edededf2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: "1000",
      }}
    >
      <ReactLoading
        type="spin"
        color="var(--purple)"
        height={100}
        width={100}
      />
      <p style={{ color: "black" }}>Uploading Your Files</p>
      <p style={{ color: "black" }}>
        This may take few minutes, please wait...
      </p>
    </div>
  );

  const ErrorAnim = () => (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        top: "0",
        left: "0",
        position: "fixed",
        backgroundColor: "#edededf2",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "center",
        zIndex: "1000",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100"
        height="100"
        fill="var(--red)"
        class="bi bi-bug"
        viewBox="0 0 16 16"
      >
        <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
      </svg>
      <br></br>
      <p style={{ color: "black" }}>
        Ooops! Something went wrong. <br></br>
        Check your internet connecion and try again.
      </p>
      <br></br>
      <Button variant="danger" href="/">
        Back to Projects
      </Button>
    </div>
  );
  //SPECIAL CHARS NOT ALLOWED
  const handleNameChange = (event) => {
    const newName = event.target.value.replace(/[^a-zA-Z\s]/g, "");
    setProjectName(newName);
  };

  //TODO:DELETE CONSOLE LOG

  //SETTING THE CSV FILE

  const setColName = () => {
    setCounter(counter + 1);
    setColumnName("Question " + counter);
  };

  const urlDownload = async () => {
    try {
      const storeForm = JSON.stringify(finalCsv);

      const file = new Blob([storeForm], { type: "text/plain" });

      const fileRef = storage
        .ref()
        .child(currentUser.uid + "/" + projectName + "/" + projectName);
      await fileRef.put(file);

      const path = fileRef.fullPath;

      const apiUrl = {};
      apiUrl.path = path;

      const response = await axios({
        method: "post",
        url: "https://api.brieferr.com/en",
        timeout: 600000,
        data: apiUrl,
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.data;

      setLoading(false);
      setIsOpen(true);
    } catch (error) {
      setIsOpen(false);
      setLoading(false);
      setErrorPage(true);
    }
  };

  useEffect(() => {
    if (
      firebase.auth().currentUser.metadata.creationTime ===
      firebase.auth().currentUser.metadata.lastSignInTime
    ) {
      setIsRun(true);
    }
  }, []);
  useEffect(() => {
    try {
      var projectRef = db
        .collection("Projects")
        .doc(projectName + "." + currentUser.uid);
      projectRef.get().then((doc) => {
        if (doc.exists) {
          setError("This project already exists. Try with another name.");
        } else {
          if (projectName === "") {
            setNoProjectName(true);
          } else {
            setProjectNameSet(true);
            setNoProjectName(false);
            setError("");
          }
        }
      });
    } catch (error) {
      setError("Something went wrong. Please try again later.");
    }
  });
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const userName = async () => {
      const Users = await db
        .collection("Users")
        .where("userID", "==", currentUser.uid)
        .get();
      setInfo(
        Users.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userName();
  }, []);

  useEffect(() => {
    const projectWarning = async () => {
      if (currentUser) {
        var docRef = await db
          .collection("ProjectCreatedUID")
          .doc(currentUser.email)
          .get();
        if (docRef.exists) {
          setProjectWarn(false);
        } else {
          setProjectWarn(true);
        }
      }
    };
    projectWarning();
  }, []);

  return (
    //TODO: CONVERT INLINE TO CSS
    <>
      <div className="d-flex">
        <Sidebar />
        {info &&
          info.map((user) => {
            return (
              <div
                className="page-container"
                style={{
                  position: "relative",
                  padding: "2em",
                  width: "100%",
                }}
              >
                <div
                  className="page-wrapper"
                  style={{
                    maxWidth: "900px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "3em",
                    margin: "0 auto",
                  }}
                >
                  {user.max_dashboard_count === 0 ? (
                    <div className="text-center">
                      <img width={500} src={sad}></img>
                      <h3>
                        You are out of dashboards!<br></br>
                        But don't worry, you can upgrade to get more. <br></br>
                        See our <a href="/pricing">plans here.</a>
                      </h3>{" "}
                    </div>
                  ) : (
                    <div className="w-100">
                      {loading ? <LoadingAnim></LoadingAnim> : <div></div>}
                      {errorPage ? <ErrorAnim></ErrorAnim> : <div></div>}
                      {error && <Alert variant="danger">{error}</Alert>}
                      <h2 className="pb-5 pt-3"> Create New Dashboard</h2>
                      {projectWarn ? <DownloadCSV /> : <div></div>}

                      {noProjectName && (
                        <p
                          className="small-paragraph pb-3"
                          style={{
                            color: "var(--yellow)",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <WarningAmberRoundedIcon /> To upload your file, give
                          your dashboard a name
                        </p>
                      )}
                      <p className="bold-small-paragraph input-title">
                        Title <span className="required">(Required)</span>{" "}
                      </p>
                      <Joyride
                        type="continuous"
                        showProgress
                        styles={{
                          options: {
                            primaryColor: "var(--purple)",
                          },
                        }}
                        showSkipButton={true}
                        run={isRun}
                        steps={steps}
                      />
                      <div className="texti w-100 ">
                        <TextInput
                          change={handleNameChange}
                          val={projectName}
                        />
                      </div>

                      <div
                        style={{
                          pointerEvents:
                            noProjectName || error ? "none" : "auto",
                          opacity: noProjectName || error ? "0.5" : "1",
                        }}
                        className="w-100 pt-5"
                      >
                        <p className="bold-small-paragraph input-title pb-2">
                          Upload Your File{" "}
                          <span className="required">(Required)</span>{" "}
                        </p>
                        <p
                          style={{
                            color: "var(--dark-gray)",
                            marginBottom: "0",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          Upload your form responses in CSV format.
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            id={`tooltip-top`}
                            overlay={<Tooltip>Add Columns</Tooltip>}
                          >
                            <button
                              className="add-col"
                              onClick={setColName}
                              style={{
                                border: "1px solid var(--purple)",
                                backgroundColor: "var(--soft-purple)",
                                borderRadius: "50%",
                                height: "40px",
                                width: "40px",
                                fontSize: "45px",
                                padding: "0",
                                margin: "0",
                                marginBottom: ".3em",
                              }}
                            >
                              <div
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  display: "flex",
                                  textAlign: " center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  paddingBottom: "0.2em",
                                  color: "var(--purple)",
                                }}
                              >
                                +
                              </div>
                            </button>
                          </OverlayTrigger>
                        </p>
                        <div className="importer w-100">
                          <Importer
                            assumeNoHeaders={true}
                            restartable={false}
                            processChunk={async (rows) => {
                              finalCsv = finalCsv.concat(rows);

                              // required, receives a list of parsed objects based on defined fields and user column mapping;
                              // may be called several times if file is large
                              // (if this callback returns a promise, the widget will wait for it before parsing more data)
                              callCount++;

                              // mock timeout to simulate processing
                              await new Promise((resolve) =>
                                setTimeout(resolve, 500)
                              );
                            }}
                            onClose={() => {
                              setLoading(true);
                              for (var i = 0; i < finalCsv.length; i++) {
                                var form = finalCsv[i];
                                var questions = [];
                                for (
                                  var j = 0;
                                  j < Object.keys(form).length;
                                  j++
                                ) {
                                  if (
                                    Object.keys(form)[j].includes("Question")
                                  ) {
                                    questions.push(Object.values(form)[j]);
                                  }
                                }

                                finalCsv[i] = Object.keys(finalCsv[i]).reduce(
                                  (f, key) => {
                                    if (!key.includes("Question")) {
                                      f[key] = form[key];
                                    }
                                    return f;
                                  },
                                  {}
                                );

                                finalCsv[i].questions = questions;
                              }
                              var forms = { forms: [] };
                              forms.forms.push(finalCsv);

                              // ||URL API
                              db.collection("ProjectCreatedUID")
                                .doc(currentUser.email)
                                .set({
                                  userID: currentUser.uid,
                                  email: currentUser.email,
                                });
                              db.collection("Users")
                                .doc(currentUser.uid)
                                .update({
                                  max_dashboard_count:
                                    user.max_dashboard_count - 1,
                                });

                              db.collection("Projects")
                                .doc(projectName + "." + currentUser.uid)
                                .set({
                                  name: projectName,
                                  userID: currentUser.uid,
                                  projectID:
                                    projectName + "." + currentUser.uid,
                                  email: currentUser.email,
                                  created:
                                    firebase.firestore.FieldValue.serverTimestamp(),
                                  response_count: finalCsv.length - 1,
                                })
                                .then(() => {
                                  urlDownload();
                                });

                              setError("");
                            }}
                          >
                            <ImporterField name="name" label="Name" />
                            <ImporterField name="email" label="Email" />
                            <ImporterField
                              name="Question 1"
                              label="Question 1"
                            />
                            <ImporterField
                              name={columnName}
                              label={columnName}
                              optional
                            />
                          </Importer>

                          <div>
                            <Modal
                              show={isOpen}
                              backdrop="static"
                              keyboard={false}
                            >
                              <Modal.Header>
                                <Modal.Title>
                                  Great! We have your responses.
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div
                                  className="pb-3"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <CheckCircleOutlineRoundedIcon
                                    style={{
                                      fontSize: "90px",
                                      color: "var(--green)",
                                    }}
                                  />
                                </div>
                                Your dashboard is getting ready. We will{" "}
                                <b>email</b> you when it is done!
                              </Modal.Body>
                              <Modal.Footer>
                                <a
                                  className="custom-btn"
                                  href={
                                    "/project-board/" +
                                    projectName +
                                    "." +
                                    currentUser.uid
                                  }
                                >
                                  Go to Dashboard
                                </a>
                              </Modal.Footer>
                            </Modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="import-link-wrapper d-flex w-75 align-items-center justify-content-center">
                    <a
                      href="https://blog.brieferr.com/get-started-with-brieferr/"
                      target="_blank"
                      className="link link-variant"
                    >
                      View getting started guide{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-box-arrow-up-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                        />
                      </svg>
                    </a>
                    <hr
                      style={{ transform: "rotate(90deg)", width: "3%" }}
                    ></hr>
                    <CSVLink
                      data={data}
                      filename={"brieferr-sample.csv"}
                      className="link link-variant w-auto"
                      style={{ width: "50%" }}
                      href="/"
                    >
                      Download Sample{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-download"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                    </CSVLink>
                    <hr
                      style={{ transform: "rotate(90deg)", width: "3%" }}
                    ></hr>
                    <a
                      href="https://www.brieferr.com/faq"
                      target="_blank"
                      className="link link-variant"
                    >
                      View FAQ{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-box-arrow-up-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                        />
                        <path
                          fill-rule="evenodd"
                          d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default CreateDashboard;
