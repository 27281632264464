export const data =[
  ["Name", "Email", "What do you like best?", "Do you have any suggestions?"],
  ["Anonymus", "Anonymus", "Efficiently, clean and organized. That's how you can manage and track the progress of a task, campagin, team, etc. The app offers to you different templates, for different kind of business activites you want to track and needs team collaboration.Just enter the emails of the team members you want to be included and select the kind of task you want to follow up.Great interface, very easy to follow instructions and can be implemented by a person with basic computer knowledge.", "The app is a very secure tool, and sometimes that could be a little bit anoying, when yiu have to identify yourself more than once to access to your project from a different location or ip address.Review collected by and hosted on the website."],
  ["Anonymus", "Anonymus", "Easy to create tasks within a team and easily manage it. Easy to create reminders for particular tasks. Ease of transferring tasks among team members. No need to create different jobs each month for the same work. We can easily create different teams in the software which results in effective workload management. We can mark a task for follow up if we are awaiting response from other users or teams. The user interface of the product is quite amazing which helps to easily navigate through different teams and tasks. If we require assistance from someone else on particular job, then we can just mention their name and they can also work on it. ", "Sometimes it isn't easy to view the attachments uploaded to specific tasks. Also, if we want to assign the same job to multiple users, it is currently not possible in the software. "],
  ["Anonymus", "Anonymus", "The first thing I like is the free version gives you so much to work with, you're not FORCED to purchase the premium version to make it functional. You can insert documents, track your project pogress at a glance, and communicate with multiple team members. You can break various projects into their own sections, and keep editing and updating your progress on goals as you see fit.","The visual platform is just a little clumsy to me. Keeping in mind Slack is a companion tool most people use (I use it as well as a companion tool), I find myself scrolling in various places to keep track of things we assigned a week ago and tasks I need to either tweak or delete "],
  ["Anonymus", "Anonymus", "Enables recruiters to use it in tracking applications.The app makes it easy to organize and handle meetings.Users can create meeting agendas where they can add notes on each item as the meeting progresses. ","No jest. The app empowers the individual to give suggestions for making the request and also streamline the processes for the project managers.It mandates meeting managers to delegate follow-up tasks to respective participants.It allows users to work from anywhere and collaborate with their teams from any device. "],
  ["Anonymus", "Anonymus", "It assists businesses to boost their productivity, hit targets, and remain competitive to enhance more collaboration among employees.Enables team leaders to track and manage their projects with efficinecy.With The app, you don't have to spend a load of time attending meeting statuses, checking emails, and sending messages to coordinate and organize your team. ","No discard. The app helps teams organize everything from long-term goals to day-to-day tasks in one central place so team members can know their duties, priorities, and progress.You can split projects into sections and with every section, you can create a list of tasks related to that project section.Has a tremendous UI. "],
  ["Anonymus", "Anonymus", " The app is a virtual board that offers features and options for teamwork by bringing all the work together in a shared space, it is a tool that offers boards and cards where we can share attachments, tasks and notes quickly and safely. I really like The app because it allows you to develop work over time to collaborate productively managing projects and saving time. It is excellent with full functionality as well as being flexible and intuitive.","The app is an online tool that works very well from anywhere and on any device, facilitating teamwork by defining each part of the process, it is a tool that allows the organization and prioritization of projects. It works safely and effectively with maximum solutions and is of great benefit for everyday life, it works very well, I have no dislikes."],
  ["Anonymus", "Anonymus", "We can create teams based around departments, projects involvement, or however, works best for our organization.Teams are connected with a shared calendar and conversations and can be made public or private to your enterprise.We can collaborate with contractors, partners, and vendors intuitively.", "No revulsion. We can limit access to any project and be able to create hidden teams for sensitive projects.Capacity to make teams and projects public so the entire organization can access them.You can easily export and delete data. "],
  ["Anonymus", "Anonymus", "The app assists teams to map sales funnels, create relevant content, and launch effective campaigns.You can collaborate efficiently between sales and marketing to establish effective campaigns with creativity that resonate with buyers.Helps to reach a larger audience and improve the impact of your work by running more agile campaigns that are launched on time. ", "No abhor whatsoever.The app lets you share your portfolio with stakeholders so everyone can see the status and progress of the most important initiatives.With The app, team members see how their work contribute to the company's objectives and understand the value of their strength and skills.Keep track of to-dos with a team management system."],
  ["Anonymus", "Anonymus", "It's a breeze to set up a portfolio of tasks, make task assignments, and monitor progress from a single dashboard. Ideal for small teams and does not require a software install and configuration of a project management system. The app can be used either over a web interface or a desktop/mobile app. Cross-platform availability ( form factors, operating systems) has been crucial for us and The app delivers in this context. ", "The app is a cloud-based task/project management system. So, cloud outages at one of the major public cloud providers could impact its availability. To date, we have not experienced any outages but that possibility seems likely. The app works well for simple tasks/projects with few dependencies but complex tasks/projects might require a comprehensive project management system."],
  ["Anonymus", "Anonymus", "The app is a powerful project management tool. The app allows us to have a central place online to add client projects, marketing projects, and in-house projects and keep track of them. It helps to handle ongoing and project-based work easily. It defines every work stage, which makes it easier to analyze the areas where things are stuck. ", "The user interface which makes it hard to use for first-time users."],
]