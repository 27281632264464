import React from "react";
import DetailedPricingCard from "../../design/components/DetailedPricingCards";
import Navigation from "../../design/elements/Navbar";
import Footer from "./components/partials/Footer";
import "../../design/styles/css/Landing.css";
import { Container } from "react-bootstrap";
function Pricing() {
  const free = ["CSV Upload", "1 Form Responses", "Email response in 7 days"];

  const pro = [
    "Includes all API package functionality",
    "Full Zapier integration",
    "Email response in 1 day",
    "API access",
  ];

  const ent = [
    "Includes all Pro package functionality",
    "Custom models",
    "Unlimited upload",
    "Customized pricing",
  ];
  return (
    <div>
      <Navigation />
      <h1 className="text-center pt-5"> Pricing</h1>
      <p className="text-center pb-5">
        We are here to help you scale and get faster.
        <br /> Choose a plan for your needs. <br></br>(Pay annually)
      </p>
      <Container>
        <div
          className="d-flex justify-content-center flex-wrap "
          id="pricing-cards-container" style={{gap:"2em"}}
        >
          <div className="one-card">
            <DetailedPricingCard
              price="0"
              name="Free"
              plan="FREE PLAN FEATURES"
              quota="1MB upload"
              cardP="For one-time interviews. Start for free."
              features={free}
              dash="1 Dashboard"
              support="Self Service Support"
              buttonText="Try For Free"
              link="/signup"
              small="free forever"
            />
          </div>

          <div className="one-card">
            <DetailedPricingCard
              price="130"
              name="Pro"
              quota="1GB upload"
              plan="PRO PLAN FEATURES"
              cardP="Understand better with Dashboards and use our API."
              features={pro}
              dash="Unlimited Dashboards"
              support="Email Support"
              buttonText="Get It Now"
              link="/pro-pay"
              small="per month"
            />
          </div>

          <div className="one-card">
            <DetailedPricingCard
              price="399"
              name="Enterprise"
              quota="Custom upload"
              plan="ENTERPRISE PLAN FEATURES"
              cardP="Get your custom models and take better actions."
              features={ent}
              dash="Unlimited Dashboards"
              support="24/7 Call and Email Support"
              link="/contact-form"
              buttonText="Get It Now"
              small="starts at $399 per month"
            />
          </div>
        </div>
      </Container>

      <Footer />
    </div>
  );
}

export default Pricing;
