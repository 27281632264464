import React from 'react';
import { Container } from 'react-bootstrap';
import '../styles/css/Landing.css'

function ThreeSteps() {
    return (
        <Container>
       <div className='three-steps-container'>

       <h2 className='h2-container'>The formula of customer success in 
           <span className='highlighted'> 3 simple steps </span>
        </h2>

        <div className='three-steps-wrapper'>

            <div className='d-flex flex-column align-items-center circle-text'>
                <div className='circle'>
                    <p className='landing-numbers'>
                    1
                    </p>
                </div>
                <p className='bold-small-paragraph'>
                    Upload your open ended responses
                </p>
            </div>
            
            <hr className='three-step-hr' />

            <div className='d-flex flex-column align-items-center circle-text'>
                <div className='circle'>
                    <p className='landing-numbers'>
                    2
                    </p>
                </div>
                <p className='bold-small-paragraph'>
                    Let our AI generate your dashboard
                </p>
            </div>
            <hr className='three-step-hr' />
            <div className='d-flex flex-column align-items-center circle-text'>
                <div className='circle'>
                    <p className='landing-numbers'>
                    3
                    </p>
                </div>
                <p className='bold-small-paragraph'>
                    Get all the metrics you need 
                </p>
            </div>
        </div>
       </div>
       </Container>
    );
}

export default ThreeSteps;