import React from 'react';
import UserGuide from './UserGuide';
import {Helmet} from "react-helmet";
import Footer from './components/partials/Footer';

function HowItWorks(props) {
    return (
        <div>
            <Helmet>
                <title>Brieferr | How It Works</title>
                <meta name="description" content="Helmet application" />
            </Helmet>
            <UserGuide></UserGuide>
            <Footer />
        </div>
    );
}

export default HowItWorks;