import React from "react";
import Navigation from "../nav/Navbar";
import { Tab, Row, Button, Nav, Container } from "react-bootstrap";
import Importer from "./components/images/form.jpg";
import Dash from "./components/images/landing.PNG";
import Insight from "./components/images/insight.jpg";
import Feedback from "../user/frontend/feedback/Feedback";

function UserGuide() {
  return (
    <div>
      <h1 className="text-center pt-5 pb-5">How Brieferr Works?</h1>

      <div
        className="d-flex pt-5 pb-5 align-items-center how-it-works"
        style={{ gap: "2em" }}
      >
        <div className="w-50 ">
          <img style={{ width: "100%" }} src={Importer}></img>
        </div>
        <div className="w-50">
          <h3>1. Connect your form data</h3>
          <p>
            Click create project button on your dashboard. Then drag-n-drop your
            form data in <b>csv</b> format.
          </p>
        </div>
      </div>

      <div
        className="d-flex flex-row-reverse pt-5 pb-5 align-items-center "
        id="how-it-works-rev"
        style={{ gap: "2em" }}
      >
        <div className="w-50 ">
          <img style={{ width: "100%" }} src={Dash}></img>
        </div>
        <div className="w-50">
          <h3>2. Turn your data into a dashboard</h3>
          <p>
            Use our extensive <b>NLP</b> modules to generate insights
            automatically.
          </p>
        </div>
      </div>

      <div
        className="d-flex pt-5 pb-5 align-items-center how-it-works"
        style={{ gap: "2em" }}
      >
        <div className="w-50 ">
          <img style={{ width: "100%" }} src={Insight}></img>
        </div>
        <div className="w-50">
          <h3>3. Put your dashboard to work</h3>
          <p>
            Use Brieferr's <b>insights</b> to make new information about your
            audience or customers. Understand what they <b>need</b>, how do they
            see your business and act on them.
          </p>
        </div>
      </div>

      <div className="pb-5 w-100 d-flex flex-column align-items-center justify-content-center">
        <h1 className="mt-5">Join Companies Using Brieferr</h1>
        <p className="mt-4">Create your first project for free</p>
        <Button
          variant="success"
          className="mt-4"
          style={{ padding: "1em 2em" }}
          href="/new-project"
        >
          Create Your Project
        </Button>
      </div>
    </div>
  );
}

export default UserGuide;
