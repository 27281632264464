import {useAuth} from '../../context/AuthContext'
import React,{useEffect, useState} from 'react'
import { Card ,Button, Container } from 'react-bootstrap'
import app from '../../firebase'
import Navigation from '../nav/Navbar'
import 'firebase/storage';
import 'firebase/firestore'



const db = app.firestore()

export default function AdminDash() {
    
  const {currentUser} = useAuth()
  const [projects, setProjects] = useState([])
  const [search, setSearch] = useState("");


  
  useEffect(() => {
    const userData = async () => {
        const usersProjects = await db.collection('Projects').get();
         setProjects(usersProjects.docs.map(doc => {
            return doc.data()
         }))
     }
     userData()
     } )  
    return (
        <div >
        <Navigation/>

        <Container fluid className="w-100">
        <div className="d-flex justify-content-center mt-4">
          <Button href="/admin-change-user-name">Change User Name</Button>
          </div>
          <div className="d-flex justify-content-center mt-4">
          <Button href="/admin-delete-account">Delete User Accounts</Button>
          </div>
          <h1 style={{textAlign:"center  ", marginTop:"2rem"}} >Projects</h1>
          <div className="d-flex justify-content-center mt-4">

          <input type="text" placeholder="Search..."onChange={(event) => {
            setSearch(event.target.value);
          }}/>
          </div>
          
              <ul>
                {projects.filter((project => {
                  if (setSearch === ("")){
                      return project
                  }
                  else if (project.name && project.name.toLowerCase().includes(search.toLowerCase())){                    
                    return project
                  }
                  
                })).map(project => {
                    return( <Card className="mt-3">
                    <Card.Body >
                      <Card.Title>
                      <Card.Link href={"/admin-project-details/"+project.projectID}>
                        {project.name}
                        </Card.Link>
                        </Card.Title>
                 

                      <small><b>user ID:</b> {project.userID}</small><br></br>
                      <small><b>user email:</b> {project.email}</small>
                    </Card.Body>
          </Card>
                    ) 
                })}
            </ul>

</Container>
<hr></hr>
        </div>

    )
}