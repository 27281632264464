import React, { useState, useRef } from "react";
import {
  Modal,
  Form,
  Button,
  Card,
  ModalBody,
  Accordion,
} from "react-bootstrap";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { Check2Circle } from "react-bootstrap-icons";
import Faq from "./Faq";

init("user_VunEIO9kDbmWZSsFxLXC0");

function Feedback() {
  const [showFeedback, setShowFeedback] = useState(false);
  const [showFAQ, setShowFAQ] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const emailFormOpen = () => {
    setShowFeedback(false);
    setShowEmail(true);
  };

  const faqOpen = () => {
    setShowFeedback(false);
    setShowFAQ(true);
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g4rcroj",
        "template_apdg5xw",
        form.current,
        "user_VunEIO9kDbmWZSsFxLXC0"
      )
      .then(
        (result) => {},
        (error) => {}
      );
  };

  const messageSuccess = () => {
    setShowEmail(false);
    setShowSuccess(true);
  };
  return (
    <div>
      <button
        style={{
          boxShadow: "2px 2px 10px 2px #5656",
          backgroundColor: "var(--purple)",
          border: "1px solid var(--purple)",
          borderRadius: "50%",
          width: "100px",
          height: "100px",
        }}
        onClick={() => setShowFeedback(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="white"
          class="bi bi-chat-fill"
          viewBox="0 0 16 16"
        >
          <path d="M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z" />
        </svg>
      </button>
      <Modal
        show={showFeedback}
        onHide={() => setShowFeedback(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Give Us a Feedback
          </Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <Card>
            <Card.Body>
              <Form>
                <h5>Start a conversation</h5>

                <small
                  style={{
                    color: "#898a8c",
                  }}
                >
                  Our usual reply time
                </small>

                <p
                  style={{
                    display: "flex",
                    gap: ".5em",
                    alignItems: "center",
                    color: "black",
                    fontSize: "16px",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="var(--purple)"
                    class="bi bi-clock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>{" "}
                  A day
                </p>

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      display: "flex",
                      gap: ".5em",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    className="custom-btn"
                    onClick={emailFormOpen}
                  >
                    Send us a message
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-send-fill"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89.471-1.178-1.178.471L5.93 9.363l.338.215a.5.5 0 0 1 .154.154l.215.338 7.494-7.494Z"
                      />
                    </svg>
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card>

          <Card style={{ cursor: "pointer" }} onClick={faqOpen}>
            <Card.Body
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div onClick={faqOpen}>
                <h5>Look up FAQs</h5>
                <small
                  style={{
                    color: "#898a8c",
                  }}
                >
                  Commonly asked questions
                </small>
              </div>

              <button
                style={{
                  backgroundColor: "#fff",
                  border: "none",
                }}
                onClick={faqOpen}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="var(--purple)"
                  class="bi bi-chevron-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                  />
                </svg>
              </button>
            </Card.Body>
          </Card>

          <Card style={{ cursor: "pointer" }}>
            <a
              href="https://blog.brieferr.com/get-started-with-brieferr/"
              target="_blank"
              style={{ color: "black", textDecoration: "none" }}
            >
              <Card.Body
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <h5>Getting started guide</h5>
                  <small
                    style={{
                      color: "#898a8c",
                    }}
                  >
                    Step-by-step walkthrough
                  </small>
                </div>

                <button
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                  }}
                  href="https://blog.brieferr.com/get-started-with-brieferr/"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="var(--purple)"
                    class="bi bi-chevron-right"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                    />
                  </svg>
                </button>
              </Card.Body>
            </a>
          </Card>
        </Modal.Body>
      </Modal>

      <Modal
        show={showFAQ}
        onHide={() => setShowFAQ(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>FAQ</Modal.Title>
        </Modal.Header>

        <Modal.Body
          style={{ display: "flex", flexDirection: "column", gap: "1em" }}
        >
          <Faq
            question="What is Brieferr, how is it useful?"
            mainText="Brieferr is a feedback/survey/ticket analysis
      platform converting long interview answers "
            highlightedText="into data points. "
            continueMainText="Review insights in your 
      one-page dashboard to give the best decisions 
      for customer success."
          />

          <Faq
            question="How do I get started with Brieferr?"
            mainText="See our "
            link="https://blog.brieferr.com/get-started-with-brieferr/"
            linkText="starting guide here."
          />

          <Faq
            question="Which analysis does Brieferr do?"
            mainText="We use NLP techniquesto give you 
      the best results. "
            highlightedText="Sentiment analysis "
            continueMainText="for overall 
      satisfaction, Entity analysis for word cloud, Summary 
      analysis for summary generation and extraction, 
      and a combination of these for detecting 
      Compliment and Complaints."
          />

          <Faq
            question="Can Brieferr analyze all my existing forms, or do the documents have to be created on your site?"
            mainText="We can analyze any existing forms; you need to "
            highlightedText="upload them as a CSV file. "
            continueMainText="Also, our Zapier and Typeform integrations are about to launch."
          />

          <Faq
            question="Can I automate Briferr workflow?"
            mainText="Yes, you can connect any form platform with Brieferr using "
            link="https://zapier.com/apps/brieferr/integrations"
            linkText="Zapier Integrations."
          />

          <Faq
            question="Can I cancel or upgrade my plan?"
            mainText="Yes, you can cancel your plan anytime; you will be able to use the service until the end of the billing period."
          />
          <Faq
            question="What is included in Free Plan?"
            mainText="You can create one dashboard and analyze one-time surveys with the free plan. You will need a Pro plan for integrations and an additional dashboard. "
            link="https://www.brieferr.com/pricing"
            linkText="See Pricing page."
          />

          <Faq
            question="Can Brieferr be used for B2C surveys?"
            mainText="Yes, Brieferr can be used for B2C or B2B surveys."
          />

          <Faq
            question="How do you use cookies?"
            mainText="We use cookies to optimize the user interface by collecting analytics to develop the handiest UI."
          />

          <Faq
            question="How can I reset my account password?"
            mainText="Go to  "
            link="https://www.brieferr.com/forgot-password"
            linkText="Forgot Password Page "
            continueMainText="and follow the instructions."
          />
        </Modal.Body>
      </Modal>

      <Modal show={showEmail} onHide={() => setShowEmail(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Give Us a Feedback
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={form} onSubmit={sendEmail}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" name="user_name" required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" name="user_email" required />
            </Form.Group>

            <Form.Group>
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" name="message" required />
            </Form.Group>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                type="submit"
                onClick={messageSuccess}
                className="custom-btn"
              >
                Submit
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <ModalBody>
            <div className="d-flex justify-content-center mt-1 mb-2">
              <Check2Circle
                width={100}
                height={100}
                color="#198754"
              ></Check2Circle>
            </div>
            We have successfully recieved your email. Thank you for your
            feedback!
          </ModalBody>
        </Modal.Header>
      </Modal>
    </div>
  );
}

export default Feedback;
