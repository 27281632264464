import React from 'react';

function DataProtectionPolicy(props) {
    return (
        <div>
            <h1>PERSONAL DATA PROTECTION AND PRIVACY POLICY</h1>
            <p>The purpose of this Privacy Policy is that shared with the Company by the site users/members/visitors (data owner) during the operation of the <a href="https://www.brieferr.com/">https://www.brieferr.com</a> (site) website operated by AIX Artificial Intelligence Technologies Anonim Şirketi (Company) is to determine the terms and conditions regarding the use of personal data generated by the Company during the use of the site by the data owner.</p>
<p>The Data Owner declares that he has been informed about the processing of his personal data with this Privacy Policy and that he consents to the use of his personal data as specified here.</p>
<p><strong>1. DEFINITIONS</strong></p>
<p>For the purposes of this Privacy Policy:</p>
<ul>
<li>Account means a unique account created for you to access our Service or parts of our Service.</li>
<li>The Company (referred to as &quot;Company&quot;, &quot;We&quot;, &quot;We&quot; or &quot;Our&quot; in this Agreement) AIX Yapay Zeka Teknolojileri A.Ş., Idealtepe, Dik Sk. No:13, 34841 Maltepe/Istanbul.</li>
<li>Cookies are small files placed by a website on your computer, mobile device, or any other device that contain, among many uses, details of your browsing history on that Website.</li>
<li>Country referred to: Turkey</li>
<li>Device; means any device that can access the Service, such as a computer, mobile phone, or digital tablet.</li>
<li>Personal Data is any information relating to an identified or identifiable natural person.</li>
<li>Service means the Website.</li>
<li>Service Provider means any natural or legal person who processes data on behalf of the Company. Means third party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform the Service-related services, or to assist the Company in analyzing how the Service is used.</li>
<li>Third-Party Social Media Service means any website or any social networking website where a user can log in or create an account to use the Service.</li>
<li>Usage Data refers to data collected automatically through the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
<li>The Website refers to Brieferr, available at <a href="https://www.brieferr.com/">https://www.brieferr.com</a>.</li>
</ul>
<p><strong>2. COLLECTING AND USING YOUR PERSONAL DATA</strong></p>
<p><strong>2.1 Types of Data Collected</strong></p>
<p>Under this heading, the data processed by the Company and considered as personal data in accordance with the Law on the Protection of Personal Data are listed. Personally, identifiable information may include, but is not limited to:</p>
<ul>
<li>E-mail address</li>
<li>Name and surname</li>
<li>Phone number</li>
<li>Address, State, Province, Post/Zip code, City</li>
<li>Usage Data</li>
</ul>
<p>Usage Data is collected automatically while using the Service. Usage Data may include information such as your Device&#39;s Internet Protocol address (e.g., IP address), browser type, browser version, pages of our Service you visit, time and date of your visit, time spent on these pages, unique device. Identifiers and other diagnostic data.</p>
<p>When you access the Service through a mobile device, we may automatically collect certain information, including but not limited to the type of mobile device you are using, the unique ID of your mobile device, the IP address of your mobile device, your mobile phone. Operating system, type of mobile Internet browser you are using, unique device identifiers, and other diagnostic data.</p>
<p>We may also collect information your browser sends each time you visit our Service or access the Service via or through a mobile device.</p>
<p><strong>2.2 Information Received from Third-Party Social Media Services</strong></p>
<p>The Company permits you to create an account and log in to use the Service through the following Third Party Social Media Services:</p>
<ul>
<li>Google</li>
<li>Facebook</li>
<li>Twitter</li>
</ul>
<p>If you decide to register or otherwise grant us access through a Third Party Social Media Service, we may collect personal data already associated with your Third Party Social Media Service&#39;s account, such as your name, e-mail address, activities. You may also have the option to share additional information with the Company through your Third Party Social Media Service&#39;s account. If you choose to provide such information and Personal Data at registration or otherwise, you authorize the Company to use, share and store them in accordance with this Privacy Policy.</p>
<p><strong>3. TRACKING TECHNOLOGIES AND COOKIES</strong></p>
<p>We use cookies and similar tracking technologies to track the activity on our Service and store certain information. The tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service. Technologies we use may include:</p>
<p><strong>3.1 Cookies or Browser Cookies.</strong> A cookie is a small file placed on your device. You can instruct your browser to refuse all Cookies or to indicate when a cookie is sent. However, if you do not accept cookies, you may not be able to use some parts of our Service. Our Service may use Cookies unless you have set your browser setting to reject Cookies.</p>
<p><strong>3.2 Flash Cookies.</strong> Certain features of our Service may use locally stored objects (or Flash cookies) to collect and store information about your preferences or your activity on our Service. Flash Cookies are not managed by the same browser settings as those used for Browser Cookies. For more information on how to delete Flash Cookies, please see &quot;Where can I change the settings to disable or delete local shared objects?&quot; Read the section.</p>
<p><strong>3.3 Web Beacons.</strong> Certain parts of our Service and e-mails may contain small electronic files known as web beacons (also called clear gifs, pixel tags, and single-pixel gifs), for example, that allow the Company to count users who have visited these pages.</p>
<p><strong>3.4 Cookies can be</strong> &quot;Persistent&quot; or &quot;Session&quot; Cookies. Persistent Cookies remain on your personal computer or mobile device when you are offline, while Session Cookies are deleted as soon as you close your web browser.</p>
<p><strong>WE USE BOTH SESSION AND PERMANENT COOKIES FOR THE FOLLOWING PURPOSES:</strong></p>
<ul>
<li><strong>Required / Essential Cookies</strong></li>
</ul>
<p>Type: Managed by: Session Cookies Purpose: These Cookies are necessary to provide you with the services offered through the Website and to enable you to use certain features of the site. They help authenticate users and prevent user accounts from being used for fraudulent purposes. Without these Cookies, the services you request cannot be provided, and we only use these Cookies to provide these services to You.</p>
<ul>
<li><strong>Cookie Policy / Notification Acceptance Cookie</strong></li>
</ul>
<p>Type: Managed By Persistent Cookies: Us Purpose: These Cookies determine whether users accept the use of cookies on the Website.</p>
<ul>
<li><strong>Functionality Cookies</strong></li>
</ul>
<p>Type: Managed by Persistent Cookies: Us Purpose: These cookies allow us to remember choices you make when you use the Website, such as remembering your login information or language preference. The purpose of these cookies is to provide you with a more personalized experience and to prevent you from having to re-enter your preferences each time you use the Website.</p>
<p>For more information about the cookies we use and your preferences regarding cookies, please visit our Cookie Policy or the Cookies section of our Privacy Policy.</p>
<p><strong>4. PURPOSE AND SHARING OF YOUR PERSONAL DATA</strong></p>
<p><strong>4.1 THE COMPANY MAY USE PERSONAL DATA FOR THE FOLLOWING PURPOSES:</strong></p>
<ul>
<li>To <strong>provide and maintain our Service, including monitoring the use of our Service.</strong></li>
<li><strong>To manage your account:</strong> The Personal Data you provide to manage your registration as a user of the Service may enable you to access different functions of the Service available to you as a registered user.</li>
<li><strong>For the performance of a contract:</strong> Development, compliance, and undertaking of a purchase contract for products, items, or services you have purchased, or any contract with Us through the Service.</li>
<li><strong>To communicate with You:</strong> Necessary for its implementation, or when reasonable.</li>
<li><strong>To provide you with news, special offers, and general information about other goods, services, and events we offer similar to products you have previously purchased or enquired about unless you choose not to receive such information.</strong></li>
<li>To <strong>manage your</strong> requests.</li>
<li><strong>To</strong> participate in and manage your requests to us.</li>
<li>For <strong>business transfers: We may use your</strong> information to evaluate or conduct a merger, divestiture, restructuring, restructuring, liquidation, or liquidation of some or all of our assets, whether as a going concern or bankruptcy.</li>
<li><strong>For other purposes: We</strong> may use your information <strong>for other purposes such as</strong> Data analysis, determining usage trends, determining the effectiveness of our promotional campaigns, and evaluating and improving your Service, our products, services, marketing, and experience.</li>
</ul>
<p><strong>4.2 WE MAY SHARE YOUR PERSONAL INFORMATION IN THE FOLLOWING CASES:</strong></p>
<ul>
<li><strong>With Service Providers:</strong> We may share your personal information with Service Providers to monitor and analyze the use of our Service and to contact you.</li>
<li><strong>For business transfers:</strong> We may share or transfer your personal information in connection with or during negotiations in any merger, sale of company assets, financing, or acquisition of all or part of our business to another company.</li>
<li><strong>With Affiliates:</strong> We <em>**</em>may share your information with our affiliates, in which case we will require those affiliates to comply with this Privacy Policy. Affiliates include our parent company and other subsidiaries, joint venture partners, or other companies that we control or are under common control with us.</li>
<li><strong>With business partners:</strong> We may share your information with our business partners in order to offer you certain products, services, or promotions.</li>
<li><strong>With other users:</strong> When <em>**</em>You share personal information or otherwise interact with other users in public areas, such information may be viewed by all users and publicly distributed outside. If you interact with other users or register through a Third Party Social Media Service, your contacts on the Third Party Social Media Service may see your name, profile, pictures, and description of your activity. Similarly, other users can view descriptions of your activity, communicate with You, and view your profile.</li>
</ul>
<p><strong>5. STORAGE OF YOUR PERSONAL DATA</strong></p>
<p>We will only retain your Personal Data for as long as necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we need to retain your data to comply with applicable Law), resolve disputes, and enforce our legal agreements and policies.</p>
<p>We will also retain Usage Data for internal analysis purposes. Usage Data is usually retained for a shorter period of time unless this data is used to strengthen the security or improve the functionality of our Service, or we are legally required to retain this data for longer periods.</p>
<p><strong>6. TRANSFER OF YOUR PERSONAL DATA</strong></p>
<p>Your information, including your Personal Data, is processed at the Company&#39;s operating offices and other locations where the parties involved in the processing are located. This means that this information may be transferred to and stored on computers located outside of your state, province, country, or other governmental jurisdiction where data protection laws may differ from Your jurisdiction.</p>
<p>Your submission of such information following your consent to this Privacy Policy represents your agreement to this transfer.</p>
<p>We will take all steps reasonably necessary to ensure that your data is handled securely and in accordance with this Privacy Policy, and we will not transfer your data and other personal information to any organization or country unless there are adequate controls, including the security of your Personal Data.</p>
<p>B<strong>usiness Transactions</strong></p>
<p>We may transfer your Personal Data if the Company is involved in a merger, acquisition, or sale of assets. We will notify you about this before your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
<p><strong>Legal Requirements</strong></p>
<p>While we keep your Personal Data strictly, we may share your data with third parties if necessary under the following terms and conditions.</p>
<ul>
<li>To comply with a legal obligation (in cases where sharing is necessary and compulsory by Law or in line with the requests of official authorities)</li>
<li>To protect and defend the rights or property of the Company.</li>
<li>To prevent or investigate possible abuses related to the Service,</li>
<li>Protecting the personal safety of Service Users</li>
<li>To ensure the public interest.</li>
</ul>
<p><strong>7. SECURITY OF YOUR PERSONAL DATA</strong></p>
<p>The security of your Personal Data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we remind you that we cannot guarantee its absolute security.</p>
<p><strong>8. PRIVACY OF CHILDREN AND REPRESENTED RESTRICTIONS</strong></p>
<p>In line with our company policy, we show the necessary sensitivity to children and attach importance to children&#39;s rights. For this reason, we strongly remind you that our Service is over the age of 13.</p>
<p>We strongly point out that we do not intentionally collect any personally identifiable data from individuals under the age of 13 and restrict individuals who are required to be represented by someone else according to national Law, without verification of authorized parent/guardian consent.</p>
<p>If we need to rely on consent as the legal basis for processing your information, and if you are legally represented by someone else under your national Law and your transactions require the consent of the person authorized to represent you, we may seek the consent of your legal representative before collecting and using this information.</p>
<p>If you are a parent/guardian authorized to represent the Restricted and you notice that the person you represent has assigned us Personal Data, please Contact Us.</p>
<p>If we become aware that we have collected Personal Data from anyone under the age of 13 and are restricted legally represented by someone else without authorized parent/guardian consent verification, we will take steps to remove that information from our servers.</p>
<p><strong>9. LINKS TO OTHER WEBSITES</strong></p>
<p>Our Service may contain links to other websites not operated by us. If you click on the link that provides access to the aforementioned websites, you will be directed to the relevant site.</p>
<p>We remind you that if you click on the link of any website not operated by us, you have no control over the content, privacy policies, or practices of a third-party site or Service.</p>
<p>Strongly recommends that you review the Privacy Policy of the site you are visiting; If you experience any grievances arising from this visit, we clearly inform you that we do not accept any legal or criminal responsibility within our explanations.</p>
<p><strong>10. UPDATES TO THE PRIVACY POLICY:</strong></p>
<p>We may update our Privacy Policy from time to time. If the privacy policy is updated;</p>
<ul>
<li>Before the privacy policy is updated, we will notify you of the update via e-mail or prominent notice.</li>
<li>We will notify you on the Website about changes in our policy by posting the current privacy policy on this page.</li>
<li>The update made in the Privacy Policy will become effective as of the date it is published on the Website.</li>
<li>We recommend that you periodically review the Privacy Policy published on our Website in order to avoid any loss of rights.</li>
</ul>
<p><strong>11. CONTACT</strong></p>
<p>For questions regarding this Privacy Policy, you can contact us using the contact information below.</p>
<ul>
<li>By e-mail: <a href="mailto:hi@brieferr.com">hi@brieferr.com</a></li>
<li>AIX Artificial Intelligence Technologies Joint Stock Company:</li>
</ul>
<p>Idealtepe, Dik Sk. No:13, 34841 Maltepe/Istanbul.</p>
<p><strong>UPDATED DATE: 21.10.2021</strong></p>

        </div>
    );
}

export default DataProtectionPolicy;