import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import app from "../../firebase";
import AppsumoPlan from "../components/AppsumoPlan";
import Sidebar from "../elements/Sidebar";
import MobileSidebar from "../elements/MobileSidebar";
import { Container } from "react-bootstrap";

const db = app.firestore();

function ProfileCurrentPlan() {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState();
  var appsumo = [
    "Unlimited dashboards",
    "1GB upload size",
    "CSV upload (up to 1GB per upload",
    "Zapier integration",
    "Email support",
    "Sentiment analysis",
    "Named-entity analysis",
    "Summary generator",
    "API access and unlimited API calls (up to 1GB per call)",
  ];
  var free = [
    "1 dashboard",
    "1GB upload size",
    "CSV upload (up to 1GB per upload",
    "Sentiment analysis",
    "Named-entity analysis",
    "Summary generator",
  ];
  useEffect(() => {
    const userName = async () => {
      const Users = await db
        .collection("Users")
        .where("userID", "==", currentUser.uid)
        .get();
      setUserData(
        Users.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userName();
  }, []);

  return (
    <div>
      <>
        <div className="d-flex w-100 align-items-evenly dashboards-wrapper">
          <Sidebar />
          <MobileSidebar />
          {userData &&
            userData.map((user) => {
              if (user.plan === "appsumo") {
                return (
                  <AppsumoPlan
                    elements={appsumo}
                    plan="AppSumo Life Time"
                    paragraph="This account purchased via AppSumo. It includes all PRO plan functionailties."
                  />
                );
              }
              if (user.plan === "paid") {
                return (
                  <AppsumoPlan
                    elements={appsumo}
                    plan="Paid Plan"
                    paragraph=""
                  />
                );
              }
              if (user.plan === "free") {
                return (
                  <AppsumoPlan elements={free} plan="Free Plan" paragraph="" />
                );
              }
            })}
        </div>
      </>
    </div>
  );
}

export default ProfileCurrentPlan;
