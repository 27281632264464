import React from 'react';

const ImageAndText = (props) => {
    return (
        <div className='d-flex align-items-center justify-content-center' 
        id='image-text-wrapper'
        style={{
            flexDirection: props.flex === "normal" ? "row" : "row-reverse" ,
            gap: "2em"
        }}>
            <div 
            id="text"
            style={{textAlign: props.flex === "normal" ? "left" : "right" }}
            className='w-50'>
                <p 
                style={{color:"var(--purple)"}}
                className='small-paragraph'>{props.smallText}</p>
                <h2>{props.heading}</h2>
                <p>
                    {props.paragraph}
                </p>
            </div>
            <div 
            id='text'
            className='w-50'>
                <img
                style={{width:"100%"}} 
                src={props.image}></img>
            </div>
        </div>
    );
};

export default ImageAndText;