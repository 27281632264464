import { useAuth } from "../../../context/AuthContext";
import { Alert } from "bootstrap";
import React, { useState } from "react";
import {
  Card,
  Button,
  Container,
  NavItem,
  Dropdown,
  NavLink,
  Nav,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import UserSettings from "./UserSettings";
import Feedback from "../frontend/feedback/Feedback";
import { Helmet } from "react-helmet";
import Sidebar from "../../../design/elements/Sidebar";
import MobileSidebar from "../../../design/elements/MobileSidebar";

export default function Profile() {
  return (
    <div>
      <Helmet>
        <title>Brieferr | Profile Settings </title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Sidebar />
      <MobileSidebar />
      <div
        className="page-container"
        style={{
          position: "relative",
          padding: "2em",
          width: "100%",
        }}
      >
        <div
          className="page-wrapper"
          style={{
            maxWidth: "900px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "3em",
            margin: "0 auto",
          }}
        >
          <UserSettings />
        </div>
      </div>
    </div>
  );
}
