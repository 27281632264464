import React from 'react';
import {Accordion, Card} from 'react-bootstrap'


function Faq(props) {

    return (
        <div style={{cursor:"pointer"}}>
<Accordion>
  <Card>
      <Card.Body style={{diplay:"flex",
    alignItems:"center"}}>
    <Accordion.Toggle
    style={{margin: 0}}
    as={Card.Title} eventKey="0">
     <h5
     style={{
       fontSize:"18px",
       padding: 0,
       margin: 0
     }}>{props.question}</h5>
    </Accordion.Toggle>
    
    <Accordion.Collapse eventKey="0">
      <Card.Body >
      <li style={{
        color:"black",
        fontSize:"16px"
      }}>
      {props.mainText}
        <a
        style={{
        color: "var(--orange)",
        textDecoration:"underline"
        }}
        href={props.link} target="_blank">{props.linkText}</a>

        <span style={{background: "-webkit-linear-gradient(right, var(--purple), var(--orange))",
    opacity: "80%",
    webkitBackgroundClip: "text",
    backgroundClip: "text",
    webkitTextFillColor: "transparent",
    }}>
      {props.highlightedText}
      
       </span>
      {props.continueMainText}
      </li>

      </Card.Body>
      
      
    </Accordion.Collapse>
    </Card.Body>

  </Card>
  
  
</Accordion>
        </div>
    );
}

export default Faq;