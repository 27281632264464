import React, {useState} from 'react'
import AnchorLink  from 'react-anchor-link-smooth-scroll'
import {Container} from 'react-bootstrap'
import  Feedback  from '../../../design/styles/images/landing.svg'
import Button, {SecondaryButton} from '../../../design/components/PrimaryButton'
import chev from '../../../design/styles/icons/chevron.svg'
export default function Landing() {

  

  return (
    <div>
      
       
      <Container className="pt-5">
      
          <div className=" text-left landing d-flex" style={{
            gap:"2em"
          }} >
            <div className="w-50" id="landing-text">
            <h1 >
            New way of understanding your audience
            </h1>
            <br></br>
            <p className='banner-paragraph'>
            Use any form platform and turn responses into comprehensive dashboards to boost your customer success
            </p>
        <div className="d-flex flex-column">
              <br></br>
            <div className="d-flex flex-row w-75" id="btn-contianer"style={{gap:"2em"}}>
            <Button text="Get Started" icon={chev} link="/signup" />
            <SecondaryButton text="Live Demo" link="/live-demo" />
            </div>
            
            </div>
          </div>

          <div className="d-flex w-50" id="new-landing-img">
          <img style={{width:"105%", height:"93%"}} src={Feedback} ></img>
          </div>
          
          </div>
      </Container>
    </div>
  )
}
