import React from "react";
import { Container } from "react-bootstrap";
import Signup from "./components/user/backend/Signup";
import { AuthProvider } from "./context/AuthContext";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./components/user/frontend/Projects";
import Login from "./components/user/backend/Login";
import PrivateRoute from "./Routes/PrivateRoute";
import ForgotPassword from "./components/user/backend/ForgotPassword";
import UpdateProfile from "./components/user/backend/UpdateProfile";
import Profile from "./components/user/frontend/Profile";
import "./App.css";
import Home from "./components/page/Home";
import Dashtry from "./components/user/frontend/inner-pages/Dashtry";
import NotFound from "./components/page/NotFound";
// import { user } from 'firebase-functions/lib/providers/auth';
import UserInfo from "./components/user/backend/UserInfo";
import LiveDemo from "./components/page/LiveDemo";
import Issues from "./components/page/Issues";
import HowItWorks from "./components/page/HowItWorks";
import UserGuideWorks from "./components/page/UserGuideWorks";
import Terms from "./components/page/components/Terms";
import DataPolicy from "./components/page/components/DataPolicy";
import About from "./components/page/About";
import DistanceSalesAgreement from "./components/page/DistanceSalesAgreement";
import DeliveryAndReturn from "./components/page/components/DeliveryAndReturn";
import Contact from "./components/page/Contact";
import AdminDash from "./components/admin/AdminDash";
import AdminProjectDetails from "./components/admin/AdminProjectDetails";
import AdminRoute from "./Routes/AdminRoute";
import Pricing from "./components/page/Pricing";
import ChangeUserName from "./components/admin/ChangeUserName";
import { Ex } from "../src/design/components/Cards";
import CreateDashboard from "./design/pages/CreateDashboard";
import InsideDash from "./components/user/frontend/inner-pages/InsideDash";
import { Redirect } from "react-router-dom";
import ForEnterprises from "./design/pages/Use Cases/ForEnterprises";
import ForDevelopers from "./design/pages/Use Cases/ForDevelopers";
import ForFounders from "./design/pages/Use Cases/ForFounders";
import AppSumoSignup from "./design/pages/AppSumoSignup";
import AppSumoLogin from "./design/pages/AppSumoLogin";
import ProfileCurrentPlan from "./design/pages/ProfileCurrentPlan";
import AppsumoUserInfo from "./design/pages/AppsumoUserInfo";
import Help from "./design/pages/Help";
import Faqs from "./design/pages/Faqs";
import Tawk from "./components/page/components/partials/Tawk";
import ForRetailers from "./design/pages/Use Cases/ForRetailers";

function App() {
  return (
    <AuthProvider>
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/home" component={Home}></Route>
            <Route
              path="/html"
              render={() => {
                window.location.href = "iyzi.html";
              }}
            />
            <Route
              path="/api-pay"
              component={() => {
                window.location.href = "https://iyzi.link/AHy4Ag";
                return null;
              }}
            />
            <Route
              path="/pro-pay"
              component={() => {
                window.location.href = "https://iyzi.link/AHy3_w";
                return null;
              }}
            />
            <Route path="/cards" component={Ex}></Route>
            <Route path="/pricing" component={Pricing}></Route>
            <Route path="/help" component={Help}></Route>
            <Route path="/faq" component={Faqs}></Route>

            <Route
              path="/use-cases/for-enterprises"
              component={ForEnterprises}
            ></Route>
            <Route
              path="/use-cases/for-founders"
              component={ForFounders}
            ></Route>
            <Route
              path="/use-cases/for-developers"
              component={ForDevelopers}
            ></Route>
             <Route
              path="/use-cases/for-retailers"
              component={ForRetailers}
            ></Route>
            {/* Appsumo deal */}
            <Route
              path="/appsumo/appsumo-signup/:id"
              component={AppSumoSignup}
            ></Route>
            <Route
              path="/appsumo/appsumo-login/:id"
              component={AppSumoLogin}
            ></Route>
            <Route
              path="/distance-sales-agreement"
              component={DistanceSalesAgreement}
            ></Route>
            <Route
              path="/delivery-and-return-agreement"
              component={DeliveryAndReturn}
            ></Route>
            <Route path="/about-us" component={About}></Route>
            <Route path="/terms" component={Terms}></Route>
            <Route path="/contact-form" component={Contact}></Route>
            <Route path="/data-policy" component={DataPolicy}></Route>
            <Route path="/live-demo" component={LiveDemo}></Route>
            <Route path="/forgot-password" component={ForgotPassword}></Route>
            <Route path="/signup" component={Signup} />
            <Route path="/login" component={Login} />
            <Route path="/how-it-works" component={HowItWorks}></Route>
            <PrivateRoute path="/user-info" component={UserInfo} />
            <PrivateRoute path="/issues" component={Issues}></PrivateRoute>
            <PrivateRoute
              path="/plan-billing"
              component={ProfileCurrentPlan}
            ></PrivateRoute>
            <PrivateRoute
              path="/user-guide"
              component={UserGuideWorks}
            ></PrivateRoute>
            <PrivateRoute
              path="/appsumo-user-information"
              component={AppsumoUserInfo}
            />
            <PrivateRoute exact path="/" component={Dashboard} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/update-profile" component={UpdateProfile} />
            <PrivateRoute path="/new-project" component={CreateDashboard} />
            <PrivateRoute path="/project-board/:id" component={InsideDash} />
            <AdminRoute path="/admin" component={AdminDash}></AdminRoute>
            <AdminRoute
              path="/admin-project-details"
              component={AdminProjectDetails}
            ></AdminRoute>
            <AdminRoute
              path="/admin-change-user-name"
              component={ChangeUserName}
            ></AdminRoute>
            <Route component={NotFound}></Route>
          </Switch>
          <Tawk />
        </AuthProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
