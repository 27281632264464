import React, { useEffect, useState } from "react";
import { Card , Row,Col,Nav,Tab, Button, Carousel, Accordion, Form} from "react-bootstrap";
import app from "../../firebase";
import { PieChart, Pie, Tooltip, Cell  } from 'recharts' 
import { useHistory } from "react-router";
import { TagCloud } from 'react-tagcloud'
import Creating from '../page/components/images/creating.png'
import Navigation from "../nav/Navbar";
import {Helmet} from "react-helmet";

const db = app.firestore();


const CreatingProject = () =>{
  return(
    <div 
    style={{height:"90vh"}}
    className="d-flex flex-column align-items-center justify-content-center text-center">
      
      <div>
        <h1>PROJECT IS BUILDING</h1>
        <p>We will email you when it's ready!</p>
        <p>This process generally takes about 10 minutes.</p>

      </div>

      <div style={{width:"40vw"}}>
        <img style={{width:"100%"}}src={Creating} ></img>
      </div>
      <small>
      <a href="mailto:hi@brieferr.com">Contact us</a> if your project takes more than 10 minutes to build.
      </small>
  </div>
  )
}

const Dashboard = () =>{
  const history = useHistory();
  const COLORS = ['#fc3003', '#FFBB28', '#00C49F'];

  const [project , setProject] = useState([]);
  const [currentProj, setCurrentProj] = useState();

  const url = window.location.href;
  const currentProject= url.split("/admin-project-details/")[1].split("%20").join(" ");
    const ProjectDelete = async () => {
    setCurrentProj(currentProject);
    await db.collection("Projects").doc(currentProject).delete().then(()=>{
      history.push("/");
    }).catch(() =>{
    })
  };

  useEffect(() => {
    const userData = async () => {
      const usersProjects = await db
        .collection("Projects")
        .where("projectID", "==", currentProject)
        .get();
      setProject(
        usersProjects.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userData();
  }, [currentProject]);


  // const TagPositive = () =>{
  //   return(
  //     <button
  //     style={{
  //       height:"max-content",
  //       width:"max-content",
  //       position:"relative",
  //       backgroundColor:"#18af87cf",
  //       display:"flex",
  //       alignItems:"center",
  //       justifyContent:"center",
  //       zIndex:"0",
  //       borderRadius:"5px",
  //       border:"2px solid var(--green)"
  //     }}>
  //       <b style={{color:"white"}}>positive</b>
  //     </button>
  //   )
  // }

  // const TagNegative = () =>{
  //   return(
  //   <button
  //   style={{
  //     height:"max-content",
  //     width:"max-content",
  //     position:"relative",
  //     backgroundColor:"#FF6171cf",
  //     display:"flex",
  //     alignItems:"center",
  //     justifyContent:"center",
  //     zIndex:"0",
  //     borderRadius:"5px",
  //     border:"2px solid var(--red)"
  //   }}>
  //     <b style={{color:"white"}}>negative</b>
  //   </button>
  //   )
  // }
  
  return (


    <ul>
     
      {project && project.map(projects => {
        const data = projects.data.final_sentiments.percentages;

       const questions = projects.data.summaries
       
       const first_form = projects.data.featured_forms


       const Compliment = ()=>{
         
        for(var i=0 ; i < 3; i++){
          var topic = projects.data.complaint_compliment[0].compliments[i].topic
          var topic_count = projects.data.complaint_compliment[0].compliments[i].topic_count
        }
        return(
          <li>{topic}({topic_count})</li>
        )
       }
       
       
  return( 

    <div>
      
    <div className="d-flex mb-3" id="main-live-demo-warpper">
    <Card className="mt-3 ">


    <Card.Body className="live-demo-pie" >
      <div>
      <Card.Title>
      Average Sentiment
        </Card.Title>
            <div className=" w-100 "id ="sentiment-wrapper">
               
              <div className="d-flex w-100">
                <p>Negative</p>
                <p className="text-right w-100">%{projects.data.final_sentiments.percentages[0].value}</p>
              </div>
              <div className="d-flex w-100">
                <p>Neutral</p>
                <p className="text-right w-100">%{projects.data.final_sentiments.percentages[1].value}</p>
              </div>
              <div className="d-flex w-100">
                <p>Positive</p>
                <p className="text-right w-100">%{projects.data.final_sentiments.percentages[2].value}</p>
              </div>
            </div>
           
            </div>
                        <PieChart 
                        width={300} height={300}>
                        <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={data}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#8884d8"
                            label
                        >
                         {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />))}
              </Pie>
                        <Tooltip />
                        </PieChart>
                        <Form>
                    <h5>Edit Senitment</h5>
                    <input placeholder="negative"></input>
                    <input placeholder="positive"></input>
                    <input placeholder="natural"></input>

                </Form>
             
             


    </Card.Body>
</Card >
<Card className="w-75 ml-5 " id="second-card-live-demo" >
          <Card.Body className="d-flex flex-column justify-content-around" >
            <div className="comment-wrapper  d-flex" id="comment-wrapper-comp">
              <h5 className="w-50 ">Complaints About</h5>
              <p className="text-right" style={{display:"inline-flex"}}>  
              {projects.data.complaint_compliment[0].complaints.map(complaint =>{
                return(
                 <div>
                  <li style={{listStyle: "none"}}
                  key={complaint.topic}>{complaint.topic}({complaint.topic_count})&nbsp;</li>
                  </div>
                 
                )
                
              })}
                </p>
            </div>
            <div className="comment-wrapper  d-flex" id="comment-wrapper-comp">
              <h5 className="w-50 ">Compliments About</h5>
              <p className="text-right" style={{display:"inline-flex"}}>
                {projects.data.complaint_compliment[0].compliments.map(compliment =>{
                return(
                 <div>
                  <li style={{listStyle: "none"}}
                  key={compliment.topic}>{compliment.topic}({compliment.topic_count})&nbsp;</li>
                  </div>
                 
                )
                
              })}</p>
            </div>
            <hr></hr>
              <div className="comment-wrapper d-flex flex-column">
                <Card.Title>Keyword Analysis: Top Trends</Card.Title>
                <TagCloud
                  minSize={25}
                  maxSize={40}
                  style={{width: "100%"}}
                  tags={projects.data.word_frequencies}
                  rotate={90}
                  className="simple-cloud"
                />
              </div>
              <hr
                style={{
                  margin: " 1em 0",
                }}
              ></hr>
              
              <div
                className="d-flex flex-column justify-content-around"
                style={{ margin:"auto 0"}}
              >
                

                <div>
                  <h5>Important Highlights</h5>

                  <li style={{listStyle:"none"}}>
                    {projects.data.highlights[0]}
                  </li>
                  <br></br>
                  <li style={{listStyle:"none"}}>
                    {projects.data.highlights[1]}
                  </li>

                
                  
                </div>
                
            </div>
          </Card.Body>
        </Card>

        

      </div>
      <br></br>
      
        
        
      <Card>
        <Card.Body  >
        <Card.Title style={{padding:" 0 5em"}} className="carousel-header">Summary of Each Question</Card.Title>
        
          <Carousel interval={null}>
            
      {questions.map(question=>{
                    return(
                      
                      <Carousel.Item >
                        <Card.Body style={{padding:"2em 7em"}} className="carousel-body">
                        
                           <b>
                             {question.question}
                            
                           </b><br></br>
                          {question.sum} 


                        </Card.Body>
                      </Carousel.Item>

                    )
                    
                  })}
           </Carousel>


        </Card.Body>
      </Card>
      <div>
        <Card.Title className="pt-4">Featured Forms</Card.Title>
      {first_form && first_form.map(single_form=>{
        // var tag = single_form.tag
        return(
           <Accordion defaultActiveKey="1" className="pb-2">
           <Card>
               
             <Accordion.Toggle as={Card.Header} eventKey="0">
                 <div className="d-flex ">
              <h5 className="w-75">{single_form.name}</h5>
              <h6 className="flex-row d-flex" style={{listStyle:"none"}}>

              </h6>
              </div>
             </Accordion.Toggle>
             
             <Accordion.Collapse eventKey="0">
                 
               <Card.Body >
                   <div className="d-flex flex-column">
                     <Card className="d-flex align-items-center">
                       <Card.Body>
               <TagCloud
                  minSize={30}
                  maxSize={35}
                  style={{width: "100%"}}
                  tags={single_form.word_frequencies}
                  rotate={90}
                  className="simple-cloud"
                />  
                </Card.Body>
                </Card>
                <br></br>
          <Card>
        <Card.Body  >
        <Card.Title style={{padding:" 0 5em"}}>Raw Responses</Card.Title>
          <Carousel interval={null}>
            
          {single_form.questions.map( quest=>{
            return(
          
                      <Carousel.Item >
                        <Card.Body style={{padding:"2em 7em"}}>
                        
                           <b>
                             {quest.question}
                            
                           </b><br></br>
                          {quest.answer} 


                        </Card.Body>
                      </Carousel.Item>
            )}
      )}
                    
           </Carousel>


        </Card.Body>
      </Card>
               </div>
               <hr></hr>
         
             <div className="d-flex " style={{justifyContent:"space-evenly"}}>
                 <div>
                 <Card.Title>Customer Information</Card.Title>
                 <p><b>Name: </b> {single_form.name}</p>
                 <p><b>Email: </b> {single_form.email}</p>
                 <p><b>Phone Number: </b> {single_form.phone}</p>
                 <p><b>Form ID: </b> {single_form.form_id}</p>
                 </div>
         
                 
             </div>
         
               </Card.Body>
               
               
             </Accordion.Collapse>
             
           </Card>
           
         </Accordion>
       ) })}
      </div>
      
    </div>
    ) 
    

  
})}


      <div clasmsNae="d-flex mb-3">
       
      </div>

      <div>
    
            <br></br>
            
      </div>
      <div style={{
  position:"fixed",
  height:"10vh",
  zIndex:"10",
  bottom:"3em",
  right:"3em"
}}>
<></>
</div>
      <Button onClick={ProjectDelete}>Delete Project</Button>
    </ul>
  );

  
}


export default function AdminProjectDetails() {


  const url = window.location.href;
  const currentProject= url.split("/admin-project-details/")[1].split("%20").join(" ");
    const [project , setProject] = useState([]);  
  useEffect(() => {
    const userData = async () => {
      const usersProjects = await db
        .collection("Projects")
        .where("projectID", "==", currentProject)
        .get();
      setProject(
        usersProjects.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userData();
  }, [currentProject]);

  return(
    

    <div className="d-flex flex-column">
      <Helmet>
        <title>Brieferr | Dashboard</title>
        <meta name="description" content="Helmet application" />
    </Helmet>

      <Navigation></Navigation>
      {project && project.map(thisproject => {
        return(       
        <div> 
          {thisproject.data ? <Dashboard></Dashboard> : <CreatingProject></CreatingProject>}
        </div>
          )
      })}
    </div>
   
  );
}



