import React from 'react';
import { Container } from 'react-bootstrap';
import { OrangePlans, PlansCard } from '../components/Cards';
import arrow from '../styles/icons/chevron.svg'
function FlexPlans() {

    var elements = [
        "Generate one dashboard",
        "See basic analytics",
        "Get help from our support team"
    ]
    var pro = [
        "Plans that start at 99$ per month",
        "Use Brieferr API",
        "Generate unlimited dashboards",
        "Generate custom models",
        "Get priority support",
        "Upgrade or downgrade any time"
    ]

    return (
        <div style={{margin:"5em 0"}}>
            
            <Container >
            <h2 className='text-center pb-5'><span className='highlighted'>Flexible plans</span>that fit your needs</h2>
            <div className='d-flex justify-content-center' style={{gap:"4em", flexWrap: "wrap"}}>
                <PlansCard
                elements={elements} 
                title="Free forever"
                explantation="Use our free tools as long as you need and analyze responses." />
                <OrangePlans 
                elements={pro} 
                title="Premium plans"
                explantation="Use our premium tools and services to focus on what matters the most. " />
            </div>
            <div style={{
                width: "max-content",
                margin:"0 auto",
                textAlign: "center",
                padding: "4em 0"
            }}>
                <a href='/pricing'
                style={{
                    color: "var(--purple)",
                    textDecoration:"underline",
                    paddingButtom: "3em"
                }}
                >See detailed pricing</a>
                <a  className='custom-btn mt-3' href='/signup'>Start for free <img src={arrow}></img></a>
            </div>
            </Container>
        </div>
    );
}

export default FlexPlans;