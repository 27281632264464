import React, { useState, useEffect } from 'react'
import {Tab, Tabs} from 'react-bootstrap'
import ProfileSettings from '../backend/UpdateProfile';
import UpdateEmail from '../backend/UpdateEmail';
import AccountInfo from '../backend/AccountInfo';
import 'firebase/firestore'
import app from '../../../firebase'
import { useAuth } from '../../../context/AuthContext';

const db = app.firestore();

function UserSettings (){
	const [key, setKey] = useState("update")
	const [disabled , setDisabled] = useState(1)
	const [info, setInfo] = useState([])
	const {currentUser} = useAuth()
	useEffect(() => {
		const userName = async () => {
		  const Users = await db
			.collection("Users")
			.where("userID", "==", currentUser.uid)
			.get();
		  setInfo(
			Users.docs.map((doc) => {
			  return doc.data();
			})
			
		  );
		};
		userName();
	  }, []);   

		return (
			<>
			{info && info.map((user)=>(
			<Tabs  
            className="setitngs-wrapper justify-content-center"
				id="controlled-tab-example"
				activeKey={key}
				onSelect={key => setKey(key)}
			>
				<Tab disabled={user.plan === "free" ? true : false } eventKey="home" title="API Key">
					<AccountInfo/>
				</Tab>
				<Tab eventKey="update" title="Your Profile">
					<ProfileSettings/>
				</Tab>
				<Tab eventKey="email" title="Update Email and Password">
                    <UpdateEmail></UpdateEmail>
				</Tab>
				
				
			</Tabs>
			))}
			</>
		);
	
}

export default () => (<div><UserSettings /></div>)