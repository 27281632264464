import React, { useState } from 'react';
import app from "../../firebase";
import "firebase/firestore";
import {Form, Button} from 'react-bootstrap'

function ChangeUserName() {

    const db = app.firestore();

    const onSubmit = e =>{
        e.preventDefault(); // prevent page refreshing
    
            try{
                
                db.collection('Users').doc(document.getElementById('user-id') && document.getElementById('user-id').value,
                ).update(
                   { 
                name : document.getElementById('user-name') && document.getElementById('user-name').value,
               
            } ).then(()=>{
              window.location.reload();          
            })
                
            }
            catch(error){
              
            }
            
           
    }
    
    return (
        <div>
            <Form onSubmit={onSubmit} className="d-flex flex-column align-items-center">
                <Form.Group  className="w-50">
                    <Form.Label>Name and Last Name</Form.Label>
                    <Form.Control id="user-name" type="text" required/>
                </Form.Group>
                <Form.Group  className="w-50">
                    <Form.Label>User ID</Form.Label>
                    <Form.Control id="user-id" type="text" required/>
                </Form.Group>
                <Button 
            className="w-25 btn-success" type="submit">
                Update Profile
            </Button>
            </Form>
        </div>
    );
}

export default ChangeUserName;