import React from "react";
import { Container } from "react-bootstrap";
import TeamProfile from "../../../design/components/TeamProfile";
import hus from "../../../design/styles/images/hüseyin.jpeg";
import zey from "../../../design/styles/images/zeynep.jpeg";
import yigit from "../../../design/styles/images/yigit.png";
import berke from "../../../design/styles/images/berke sarpaş.jpeg";
import bilgin from "../../../design/styles/images/bilgin.png";
import tony from "../../../design/styles/images/tony.webp";
import doruk from "../../../design/styles/images/doruk.jpeg";
import arrow from "../../../design/styles/icons/chevron.svg";
import check from "../../../design/styles/icons/check.svg";
import noor from "../../../design/styles/images/noor.jpeg";
import ozlem from "../../../design/styles/images/ozlem.jpeg";



function AboutUs() {
  return (
    <div className="mb-5">
      <Container>
        <h1 className="text-center pt-5">
          Our <span className="highlighted">Team</span>
        </h1>
        <div className="m-auto pt-3" style={{ maxWidth: "600px" }}>
          <p className="banner-paragraph text-center">
            Our mission is to help you listen to your customers to improve your
            customer success.
          </p>
        </div>
      </Container>

      <Container style={{ maxWidth: "900px" }} className="pt-4 pb-4">
        <div className="team-wrapper">
          <TeamProfile
            twitter="https://twitter.com/huseyinevecen"
            linekdin="https://www.linkedin.com/in/huseyinevecen/"
            image={hus}
            job="CEO"
            name="Hüseyin Evecen"
          />
          <TeamProfile
            linekdin="https://www.linkedin.com/in/berke-sarpas/"
            twitter="https://twitter.com/berkesarpas"
            image={berke}
            job="BIZ DEV"
            name="Berke Sarpaş"
          />
          <TeamProfile
            linekdin="https://www.linkedin.com/in/yigitsimsek/"
            image={yigit}
            job="back-end developer"
            name="Yiğit Şimşek"
          />
        </div>
        <div className="team-wrapper">
          <TeamProfile
            twitter="https://twitter.com/reginz_"
            linekdin="https://www.linkedin.com/in/zeynep-evecen-24a587151/"
            image={zey}
            job="front-end-developer"
            name="Zeynep Evecen"
          />
          <TeamProfile
            linekdin="https://www.linkedin.com/in/bilgin-kocak/"
            twitter="https://twitter.com/KocakBilgin"
            image={bilgin}
            job="data scientist"
            name="Bilgin Koçak"
          />
          <TeamProfile
            linekdin="https://www.linkedin.com/in/tony-aldemir/"
            image={tony}
            job="COO"
            name="Tony Aldemir"
          />
        </div>
        <div className="team-wrapper">
          <TeamProfile
            linekdin="https://www.linkedin.com/in/dorukyalcinsoy/"
            image={doruk}
            job="marketing"
            name="Doruk Yalçınsoy"
          />
           <TeamProfile
            linekdin="https://www.linkedin.com/in/noor-hussain-10601a143/"
            image={noor}
            job="Data Scientist"
            name="Noor Hussain"
          />
           <TeamProfile
            linekdin="https://www.linkedin.com/in/özlem-yıldız-b9605b68/"
            image={ozlem}
            job="Big Data Engineer"
            name="Özlem Yıldız"
          />
        </div>
      </Container>

      <Container
        className="pt-4 text-center pb-4"
        id="building-brieferr"
        style={{ maxWidth: "900px" }}
      >
        <h3>Our Roadmap</h3>
        <p className=" mb-4">
          We at Brieferr believe in transparency, so we publish our public
          roadmaps on our site. So that anybody interested can see our progress
          overtime and our goals for the long term. Check out our roadmap 👇
        </p>
        <div >
        <iframe class="airtable-embed" src="https://airtable.com/embed/shrkAP5CE7QhxG3nH?backgroundColor=red&viewControls=on" frameborder="0" onmousewheel="" width="100%" height="533" style={{background: "transparent", border: "1px solid var(--gray)",boxShadow: "0px 2px 11px #e5e5e5", borderRadius:"1em"}}></iframe>
        </div>
      </Container>

      <div
        style={{
          backgroundColor: "var(--gray)",
          width: "100%",
          margin: "0",
          padding: "0",
        }}
        className="mt-5"
      >
        <Container
          className="pt-4 pb-4"
          id="building-brieferr"
          style={{ maxWidth: "900px" }}
        >
          <h3>Our Company</h3>
          <p>
            Brieferr is a product of{" "}
            <a className="footer-a" href="https://aiforexistence.com/#/">
              AIX Inc.
            </a>{" "}
            We founded AIX in February 2021 in Istanbul/Turkey. We serve our
            technologies all around the world. We aim to help people in making
            better decisions and with Brieferr.
          </p>
        </Container>
      </div>

      <Container
        className="pt-4 text-right pb-4"
        id="building-brieferr"
        style={{ maxWidth: "900px" }}
      >
        <h3>Building Brieferr</h3>
        <p>
          As humans, one of our best abilities is to communicate. To do that, we
          use words and language, not numbers and rankings. So to be able to
          understand people, we shouldn’t survey; we should interview.
          <br></br>
          But it is so time-consuming to read the responses of every customer.
          So we created Brieferr to make the world more human via “computers.”
          To help you listen to your customers and improve your success.
        </p>

        <div className="w-25 mt-5 pt-5 m-auto" id="about-last">
          <a className="custom-btn mt-3 mb-3" href="/signup">
            Start for free <img src={arrow}></img>
          </a>
          <div className="m-auto text-center">
            <p className="small-paragraph">
              <img src={check}></img>
              No credit card required
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AboutUs;
