import React from "react";
import { Body } from "./Cards";
import check from '../styles/icons/check.svg'
function AppsumoPlan(props) {
  var elements = props.elements
  return (
    <div
      className="page-container"
      style={{
        position: "relative",
        padding: "2em",
        width: "100%",
      }}
    >
      <div
        className="page-wrapper"
        style={{
          maxWidth: "900px",
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "3em",
          margin: "0 auto",
        }}
      >
        <h2 className="text-left">Plan & Billing</h2>
        <Body className=' pl-0 pr-5'>
               <div className='text-left'>
                   <h3 >
                {props.plan}
                   </h3>
                   <hr style={{color:"var(--gray)" }}></hr>
               </div>
               <p>
                  {props.paragraph}
               </p>
               {elements.map((element)=>{
                   return(
                    <p>
                    <img src={check}></img>
                    {element}
                    </p>
                   )
               })}
           </Body>
      </div>
    </div>
  );
}

export default AppsumoPlan;
