import {useAuth} from '../../../context/AuthContext'
import React,{useEffect, useState} from 'react'
import { Card ,Button, Container, Alert,Modal, Form } from 'react-bootstrap'
import app from '../../../firebase'
import Navigation from '../../nav/Navbar'
import 'firebase/storage';
import 'firebase/firestore'
import Feedback from '../frontend/feedback/Feedback'
import {Helmet} from "react-helmet";
import '../../../design/styles/css/AppPages.css'
import Dash from './Dash'
import Sidebar from '../../../design/elements/Sidebar'
import MobileSidebar from '../../../design/elements/MobileSidebar'

const db = app.firestore()

const Dashboard = () => {


    return (
       <div className='d-flex w-100 align-items-evenly dashboards-wrapper'>
         <MobileSidebar/>
         <Sidebar />
         <Dash />
       </div>

    )
}
export default Dashboard;