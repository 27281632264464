import { useAuth } from "../../../context/AuthContext";
import React, { useEffect, useState } from "react";
import { Card, Button, Container, Alert, Modal, Form } from "react-bootstrap";
import app from "../../../firebase";
import "firebase/storage";
import "firebase/firestore";
import Feedback from "../frontend/feedback/Feedback";
import { Helmet } from "react-helmet";
import "../../../design/styles/css/Dashboards.css";
import magic from "../../../design/styles/icons/magic.svg";
import "../../../design/styles/css/AppPages.css";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import styled from "styled-components";
const db = app.firestore();

const Dash = () => {
  const Body = styled.div`
    padding: 1em;
  `;
  const CardCustom = styled.div`
    border-radius: 10px;
    border: 1px solid var(--soft-gray);
    min-height: 320px;
    height: 320px;
    max-height: 320px;
    transition: 0.3s ease;
    &&:hover {
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
      transition: 0.3s ease;
    }
  `;
  const COLORS = ["var(--red)", "var(--yellow)", "var(--green)"];
  const { currentUser } = useAuth();
  const [projects, setProjects] = useState([]);
  const [projectWarn, setProjectWarn] = useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    const userData = async () => {
      const usersProjects = db
        .collection("Projects")
        .where("userID", "==", currentUser.uid);

      const getProjetcs = await usersProjects.get();
      setProjects(
        getProjetcs.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userData();
  }, []);

  useEffect(() => {
    const projectWarning = async () => {
      if (currentUser) {
        var docRef = await db
          .collection("ProjectCreatedUID")
          .doc(currentUser.email)
          .get();
        if (docRef.exists) {
          setProjectWarn("");
        } else {
          setProjectWarn("You don't have any project");
        }
      }
    };
    projectWarning();
  }, []);

  //TODO: PROPS'U BURAYA TAŞI

  return (
    <div className="w-100">
      <Helmet>
        <title>Brieferr | Your Dashboards</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <div
        className="page-container"
        style={{
          position: "relative",
          padding: "2em",
          width: "100%",
        }}
      >
        <div
          className="page-wrapper"
          style={{
            maxWidth: "900px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "3em",
            margin: "0 auto",
          }}
        >
          {projectWarn && (
            <Alert
              variant="warning"
              className="d-flex justify-content-between align-items-center w-100"
            >
              {projectWarn}{" "}
              <Button variant="warning" href="/new-project">
                {" "}
                Create First Project
              </Button>
            </Alert>
          )}

          <div
            className="w-100 m-0 mobile-dashboard-view"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "3em",
            }}
          >
            <h2 className="pb-3 pt-3">Dashboards</h2>
            <input
              className="searchbar mb-3"
              style={{ fontFamily: "Fira Sans" }}
              type="text"
              placeholder=" Search for dashboards"
              onChange={(event) => {
                setSearch(event.target.value);
              }}
            />
            {projects
              .filter((project) => {
                if (setSearch === "") {
                  return project;
                } else if (
                  project.name &&
                  project.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return project;
                }
              })
              .map((project) => {
                const months = [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec",
                ];

                var timestamp = project.created.toDate();
                var day = timestamp.getDate();
                var year = timestamp.getFullYear();
                var month = months[timestamp.getMonth()];
                var data =
                  project.data && project.data.final_sentiments.percentages;
                if (data) {
                  if (data[0].value > 20) {
                    return (
                      <div key={project.name}>
                        <CardCustom
                          style={{
                            minWidth: "420px",
                            maxWidth: "420px",
                            minHeight: "320px",
                          }}
                        >
                          <Body>
                            <div className="d-flex flex-column">
                              <a
                                className="card-link-color"
                                href={"/project-board/" + project.projectID}
                              >
                                <h3 className="title-card">{project.name}</h3>
                                <p className="small-paragraph">
                                  Creation Date: {month} {day} {year}
                                </p>
                                <hr style={{ width: "100%" }}></hr>
                                <p className="quick-insight-title">
                                  Quick Insights
                                </p>
                                <div
                                  className="d-flex w-100"
                                  style={{
                                    gap: "2em",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="d-flex"
                                    style={{ gap: ".5em" }}
                                  >
                                    <svg
                                      width="25"
                                      height="25"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M11.46 0.146C11.3663 0.052531 11.2394 2.8119e-05 11.107 0L4.893 0C4.76065 2.8119e-05 4.63371 0.052531 4.54 0.146L0.146 4.54C0.052531 4.63371 2.8119e-05 4.76065 0 4.893L0 11.107C2.8119e-05 11.2394 0.052531 11.3663 0.146 11.46L4.54 15.854C4.63371 15.9475 4.76065 16 4.893 16H11.107C11.2394 16 11.3663 15.9475 11.46 15.854L15.854 11.46C15.9475 11.3663 16 11.2394 16 11.107V4.893C16 4.76065 15.9475 4.63371 15.854 4.54L11.46 0.146ZM8 4C8.535 4 8.954 4.462 8.9 4.995L8.55 8.502C8.53824 8.63977 8.4752 8.76811 8.37336 8.86164C8.27151 8.95516 8.13827 9.00705 8 9.00705C7.86173 9.00705 7.72849 8.95516 7.62664 8.86164C7.5248 8.76811 7.46176 8.63977 7.45 8.502L7.1 4.995C7.08743 4.86923 7.10134 4.74223 7.14084 4.62217C7.18035 4.5021 7.24456 4.39165 7.32934 4.29791C7.41413 4.20418 7.51761 4.12924 7.63312 4.07793C7.74863 4.02662 7.87361 4.00007 8 4V4ZM8.002 10C8.26722 10 8.52157 10.1054 8.70911 10.2929C8.89664 10.4804 9.002 10.7348 9.002 11C9.002 11.2652 8.89664 11.5196 8.70911 11.7071C8.52157 11.8946 8.26722 12 8.002 12C7.73678 12 7.48243 11.8946 7.29489 11.7071C7.10736 11.5196 7.002 11.2652 7.002 11C7.002 10.7348 7.10736 10.4804 7.29489 10.2929C7.48243 10.1054 7.73678 10 8.002 10Z"
                                        fill="#E55767"
                                      />
                                    </svg>

                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "var(--red)",
                                      }}
                                    >
                                      Your audience's negative sentiment is more
                                      than 20%.
                                    </p>
                                  </div>
                                  <PieChart width={150} height={150}>
                                    <Pie
                                      dataKey="value"
                                      isAnimationActive={false}
                                      data={data}
                                      cx="40%"
                                      cy="50%"
                                      outerRadius={40}
                                      fill="#8884d8"
                                      label
                                    >
                                      {data.map((entry, index) => (
                                        <Cell
                                          key={`cell-${index}`}
                                          fill={COLORS[index % COLORS.length]}
                                        />
                                      ))}
                                    </Pie>
                                    <Tooltip />
                                  </PieChart>
                                </div>
                              </a>
                            </div>
                          </Body>
                        </CardCustom>
                      </div>
                    );
                  } else {
                    return (
                      <div key={project.name}>
                        <CardCustom
                          style={{
                            minWidth: "420px",
                          }}
                        >
                          <Body>
                            <div
                              className="d-flex flex-column"
                              style={{ height: "100%" }}
                            >
                              <a
                                className="card-link-color"
                                href={"/project-board/" + project.projectID}
                              >
                                <h3 className="title-card">{project.name}</h3>
                                <p className="small-paragraph">
                                  Creation Date: {month} {day} {year}
                                  {project.date}
                                </p>
                                <hr style={{ width: "100%" }}></hr>
                                <p className="quick-insight-title">
                                  Quick Insights
                                </p>
                                <div className="d-flex flex-column align-items-center w-100">
                                  <div
                                    className="d-flex flex-column align-items-center w-100"
                                    style={{ color: "var(--green)" }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-check2-circle"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                                    </svg>
                                    <p
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "var(--green)",
                                      }}
                                    >
                                      Everything looks just fine
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </Body>
                        </CardCustom>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div key={project.name}>
                      <CardCustom
                        style={{
                          minWidth: "420px",
                        }}
                      >
                        <Body>
                          <div className="d-flex flex-column">
                            <a
                              className="card-link-color"
                              href={"/project-board/" + project.projectID}
                            >
                              <h3 className="title-card">{project.name}</h3>
                              <p className="small-paragraph">
                                Creation Date: {month} {day} {year}
                              </p>
                              <hr style={{ width: "100%" }}></hr>
                              <p className="quick-insight-title">
                                Quick Insights
                              </p>
                              <div className="d-flex flex-column align-items-center w-100">
                                <div className="d-flex flex-column align-items-center w-100">
                                  <img width={30} src={magic}></img>
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      color: "var(--dark-gray)",
                                    }}
                                  >
                                    Your Dashboard is Building
                                  </p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </Body>
                      </CardCustom>
                    </div>
                  );
                }
              })}
          </div>
        </div>

        <hr></hr>
      </div>
    </div>
  );
};
export default Dash;
