import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Form, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { TextInput, PasswordInput } from "../components/TextInput";
import {
  PrimaryButtonVariant,
  SecondaryButton,
} from "../components/PrimaryButton";
import { useAuth } from "../../context/AuthContext";
import app from "../../firebase";
import "firebase/firestore";
import firebase from "firebase";
import axios from "axios";

function AppSumoSignup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = window.location.href;
  const id = url.split("/appsumo/appsumo-signup/")[1];
  const db = app.firestore();
  const [resdata, setresData] = useState();

  useEffect(() => {
    async function onClick() {
      const response = await axios({
        method: "post",
        url: "https://europe-west3-auth-developement-9e9a6.cloudfunctions.net/addMessage",
        data: { appsumo_id: id, auth: "fB2jcDxbPeNhrUnT" },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      setresData(data);
      console.log(data);
    }
    onClick();
  });

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      if (passwordRef.current.value !== passwordConfirmRef.current.value) {
        return setError("Passwords do not match");
      } else {
        setError("");
        setLoading(true);
        await signup(emailRef.current.value, passwordRef.current.value);
        window.location.replace("/appsumo-user-information");
      }
    } catch (error) {
      console.log("failed");

      setError("Failed to create an account");
    }

    setLoading(false);
  }
  return (
    <div>
      <Container className="d-flex align-items-center justify-content-center signUpIn-container">
        <Helmet>
          <title>Brieferr | AppSumo Sign Up</title>
          <meta name="description" content="Helmet application" />
        </Helmet>
        <div className=" w-100">
          <Card.Body className=" w-100 ">
            <h2 className=" mb-4">
              Activate your{" "}
              <span style={{ color: "var(--purple)" }}>Brieferr account</span>
            </h2>
            <p
              className="banner-paragraph"
              style={{ color: "var(--dark-gray)" }}
            >
              One last step to activate your account from our AppSumo campaign!
            </p>
            <Form
              id="signup-form"
              className="d-flex flex-column align-items-center"
              onSubmit={handleSubmit}
            >
              {error && <Alert variant="danger"> {error}</Alert>}
              {resdata ===
              "An error occured while trying to retrieve user email." ? (
                resdata && <Alert variant="danger"> {resdata}</Alert>
              ) : (
                <div></div>
              )}

              <input
                className="appsumo-email"
                placeholder="Static Mail"
                ref={emailRef}
                type="email"
                value={resdata}
                readOnly
              ></input>

              <div className="d-flex w-100 passwords pt-3 pb-3">
                <PasswordInput
                  placeholder="Password"
                  type="password"
                  class="w-100"
                  referance={passwordRef}
                  required
                />

                <PasswordInput
                  placeholder="Verify Password"
                  class="w-100"
                  referance={passwordConfirmRef}
                  required
                />
              </div>

              <div className="w-100 pb-5">
                <PrimaryButtonVariant
                  disabled={loading}
                  text="Activate Account"
                  typ="submit"
                  id="form-group"
                ></PrimaryButtonVariant>
              </div>
            </Form>

            <small
              style={{ color: "#707070" }}
              className="w-100 text-left pb-3 pt-3"
            >
              By clicking Activate Account button, you agree to
              our{" "}
              <a href="/terms" target="_blank">
                Terms and Conditions
              </a>{" "}
              and that you read to our{" "}
              <a href="/data-policy" target="_blank">
                Data Use Policy
              </a>
              , including Cookie Use.
            </small>
          </Card.Body>
        </div>
      </Container>
    </div>
  );
}

export default AppSumoSignup;
