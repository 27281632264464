import React, {useEffect} from 'react';
import TawkTo from 'tawkto-react'

function Tawk() {
    const propertyId = "622b00631ffac05b1d7e0d45"
    const tawkId = "1ftrvj1kh"
    useEffect(() => {
    
        var tawk = new TawkTo(propertyId, tawkId)
    
        tawk.onStatusChange((status) => 
        {
             console.log(status)
        })
    
    }, [])
    return (
        <div>
            
        </div>
    );
}

export default Tawk;