import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Accordion,
  Modal,
  Card,
  OverlayTrigger,
  Tooltip as Tool,
} from "react-bootstrap";
import app from "../../../../firebase";
import { PieChart, Pie, Tooltip, Cell } from "recharts";
import { useHistory } from "react-router";
import { TagCloud } from "react-tagcloud";
import Feedback from "../feedback/Feedback";
import Creating from "../../../page/components/images/creating.png";
import { Helmet } from "react-helmet";
import { HighlightedSentences } from "../../../../design/components/Cards";
import styled from "styled-components";
import Rating from "@mui/material/Rating";
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CustomizedSteppers from "../../../../design/components/Stepper";

const db = app.firestore();

const CreatingProject = (props) => {
  const url = window.location.href;
  const currentProject = url.split("/project-board/")[1].split("%20").join(" ");
  const [project, setProject] = useState([]);

  useEffect(() => {
    const userData = async () => {
      db.collection("Projects")
        .doc(currentProject)
        .onSnapshot((doc) => {
          setProject(doc.data().status);
        });
    };
    userData();
  }, []);

  return (
    <div
      style={{ width: "80vw", height: "90vh" }}
      className="d-flex align-items-center flex-column justify-content-center"
    >
      <h2 className="pb-2">Dashboard Is Building</h2>
      <p className="pb-5 mb-4">
        We will email you when your dashboard is ready!
      </p>
      <div>
        <CustomizedSteppers step={project} />
      </div>
    </div>
  );
};

const Dashboard = () => {
  const CardCustom = styled.div`
    border-radius: 10px;
    border: 1px solid var(--soft-gray);
  `;
  const DangerCard = styled.div`
    border-radius: 10px;
    border: 3px solid var(--red);
  `;
  const Body = styled.div`
    padding: 1em;
  `;
  const CardOverflow = styled.div`
    border-radius: 10px;
    border: 1px solid var(--soft-gray);
    max-height: 300px;
    min-height: 300px;
    min-width: 300px;
    height: 100%;
    overflow: scroll;
  `;

  const history = useHistory();
  const COLORS = ["var(--red)", "var(--yellow)", "var(--green)"];
  const [project, setProject] = useState([]);
  const [currentProj, setCurrentProj] = useState();
  const [showModal, setShowModal] = useState(false);

  const url = window.location.href;
  const currentProject = url.split("/project-board/")[1].split("%20").join(" ");
  const ProjectDelete = async () => {
    setCurrentProj(currentProject);
    await db
      .collection("Projects")
      .doc(currentProject)
      .delete()
      .then(() => {
        history.push("/");
      })
      .catch(() => {});
  };

  useEffect(() => {
    const userData = async () => {
      const usersProjects = await db
        .collection("Projects")
        .where("projectID", "==", currentProject)
        .get();
      setProject(
        usersProjects.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userData();
  }, [currentProject]);

  return (
    <ul className="dashboard-wrapper">
      {project &&
        project.map((projects) => {
          const data = projects.data.final_sentiments.percentages;

          const questions = projects.data.summaries;

          const first_form = projects.data.featured_forms;
          const months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ];

          var timestamp = projects.created.toDate();
          var day = timestamp.getDate();
          var year = timestamp.getFullYear();
          var month = months[timestamp.getMonth()];

          var calcNPS = data[2].value - data[0].value;
          var calcCSAT =
            (projects.data.final_sentiments.pos_count /
              projects.response_count) *
            5;
          console.log(window.innerHeight, window.innerWidth);
          return (
            <div>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <p
                    className="small-paragraph pt-3"
                    style={{
                      margin: "0",
                    }}
                  >
                    {month} {day} {year}
                  </p>
                  <h2 className="pb-5">{projects.name}</h2>
                </div>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={
                    <Tool id={`tooltip-top`}>Download dashboard as PDF</Tool>
                  }
                >
                  <button
                    className="download-btn"
                    onClick={() => {
                      setTimeout(function () {
                        const divToPrint =
                          document.querySelector("#divToPrint");
                        html2canvas(divToPrint).then((canvas) => {
                          const imgData = canvas.toDataURL("image/png");
                          const imgWidth = 190;
                          const pageHeight = 290;
                          const imgHeight =
                            (canvas.height * imgWidth) / canvas.width;
                          let heightLeft = imgHeight;
                          const doc = new jsPDF("pt", "mm");
                          let position = 0;
                          doc.addImage(
                            imgData,
                            "PNG",
                            5,
                            0,
                            imgWidth,
                            imgHeight
                          );
                          heightLeft -= pageHeight;
                          while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            doc.addPage();
                            doc.addImage(
                              imgData,
                              "PNG",
                              5,
                              position,
                              imgWidth,
                              imgHeight
                            );
                            heightLeft -= pageHeight;
                          }
                          doc.save(`${projects.name}.pdf`);
                        });
                      }, 1000);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </OverlayTrigger>
              </div>
              <div className="d-flex mb-3" id="main-live-demo-warpper">
                <div className="d-flex mr-4 w-75 flex-column ">
                  <CardCustom className="mb-3">
                    <Body className="d-flex">
                      <div className="w-50 d-flex flex-column align-items-center h-100">
                        <div className="">
                          <h3 className="bold-small-paragraph mb-3">
                            CSAT Score{" "}
                          </h3>
                        </div>
                        <div>
                          <Rating
                            name="read-only"
                            value={calcCSAT}
                            readOnly
                            size="large"
                          />
                        </div>
                      </div>
                      <hr
                        style={{
                          transform: "rotate(90deg)",
                          borderColor: "var(--soft-gray)",
                          width: "15%",
                          margin: "auto",
                        }}
                      ></hr>
                      <div className="w-50 d-flex flex-column align-items-center h-100">
                        <div className="">
                          <h3 className="bold-small-paragraph mb-3">
                            NPS Score
                          </h3>
                        </div>
                        <h3 style={{ fontFamily: "Fira Sans" }}>{calcNPS}</h3>
                      </div>
                      <hr
                        style={{
                          transform: "rotate(90deg)",
                          borderColor: "var(--soft-gray)",
                          width: "15%",
                          margin: "auto",
                        }}
                      ></hr>
                      <div className="w-50 d-flex flex-column align-items-center justify-content-center">
                        <h3 className="bold-small-paragraph mb-3">
                          Response Count{" "}
                        </h3>
                        <h3 style={{ fontFamily: "Fira Sans" }}>
                          {projects.response_count}
                        </h3>
                      </div>
                    </Body>
                  </CardCustom>
                  <HighlightedSentences
                    sentence1={[projects.data.highlights[0]]}
                    sentence2={[projects.data.highlights[1]]}
                  />
                  <CardOverflow className="mt-3">
                    <Body>
                      <h3 className="bold-small-paragraph  mb-3">
                        Summary of Each Question{" "}
                      </h3>

                      {questions.map((question) => {
                        return (
                          <div>
                            <Accordion defaultActiveKey="0">
                              <Card>
                                <Card.Header>
                                  <Accordion.Toggle
                                    as={Button}
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                    variant="link"
                                    eventKey={question.question}
                                  >
                                    <p
                                      style={{
                                        textAlign: "left",
                                        width: "85%",
                                      }}
                                    >
                                      {question.question}
                                    </p>
                                    <div style={{ width: "8%" }}>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="40"
                                        fill="var(--purple)"
                                        class="bi bi-chevron-down"
                                        viewBox="0 0 16 16"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                        />
                                      </svg>
                                    </div>
                                  </Accordion.Toggle>
                                </Card.Header>

                                <Accordion.Collapse
                                  eventKey={question.question}
                                >
                                  <Card.Body>{question.sum}</Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            </Accordion>
                          </div>
                        );
                      })}
                    </Body>
                  </CardOverflow>
                  <div className="pt-3">
                    <CardCustom>
                      <Body>
                        <h3
                          className=" bold-small-paragraph mb-3 pt-4"
                        >
                          Featured Forms
                        </h3>
                        {first_form &&
                          first_form.map((single_form) => {
                            // var tag = single_form.tag
                            return (
                              <Accordion defaultActiveKey="1" className="pb-2">
                                <Card>
                                  <Accordion.Toggle
                                    as={Button}
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                    variant="link"
                                    eventKey="0"
                                  >
                                    <div className="d-flex ">
                                      <p style={{ margin: "0" }}>
                                        {single_form.name}
                                      </p>
                                    </div>
                                  </Accordion.Toggle>
                                </Card>
                                <Accordion.Collapse eventKey="0">
                                  <Body
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div className="d-flex flex-column">
                                      <Body
                                        style={{
                                          maxWidth: "400px",
                                          width: "100%",
                                          maxHeight: "250px",
                                          overflow: "scroll",
                                        }}
                                      >
                                        <h3 className="bold-small-paragraph">
                                          Raw Responses
                                        </h3>
                                        <Accordion>
                                          {single_form.questions.map(
                                            (quest) => {
                                              return (
                                                <div>
                                                  <Accordion defaultActiveKey="0">
                                                    <Card>
                                                      <Card.Header>
                                                        <Accordion.Toggle
                                                          as={Button}
                                                          style={{
                                                            width: "100%",
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "space-between",
                                                          }}
                                                          variant="link"
                                                          eventKey={
                                                            quest.question
                                                          }
                                                        >
                                                          {quest.question}
                                                          <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="var(--purple)"
                                                            class="bi bi-chevron-down"
                                                            viewBox="0 0 16 16"
                                                          >
                                                            <path
                                                              fill-rule="evenodd"
                                                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                                            />
                                                          </svg>
                                                        </Accordion.Toggle>
                                                      </Card.Header>

                                                      <Accordion.Collapse
                                                        eventKey={
                                                          quest.question
                                                        }
                                                      >
                                                        <Card.Body>
                                                          {quest.answer}
                                                        </Card.Body>
                                                      </Accordion.Collapse>
                                                    </Card>
                                                  </Accordion>
                                                </div>
                                              );
                                            }
                                          )}
                                        </Accordion>
                                      </Body>
                                    </div>
                                    <Body>
                                      <div
                                        className="d-flex "
                                        style={{
                                          width: "100%",
                                          maxWidth: "400px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div>
                                          <h3 className="bold-small-paragraph">
                                            Customer Information
                                          </h3>
                                          <p>
                                            <b>Name: </b> {single_form.name}
                                          </p>
                                          <p>
                                            <b>Email: </b> {single_form.email}
                                          </p>
                                          <p>
                                            <b>Phone Number: </b>{" "}
                                            {single_form.phone}
                                          </p>
                                          <p>
                                            <b>Form ID: </b>{" "}
                                            {single_form.form_id}
                                          </p>
                                        </div>
                                      </div>
                                    </Body>
                                  </Body>
                                </Accordion.Collapse>
                              </Accordion>
                            );
                          })}
                      </Body>
                    </CardCustom>
                  </div>
                </div>

                <div className="d-flex flex-column ">
                  <CardCustom style={{ width: "300px" }}>
                    <Body className="live-demo-pie">
                      <div>
                        <h3 className="bold-small-paragraph">Sentiment</h3>
                      </div>
                      <div
                        style={{
                          margin: "0 auto",
                        }}
                      >
                        <PieChart width={250} height={250}>
                          <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={data}
                            cx="50%"
                            cy="50%"
                            style={{ width: "100px" }}
                            outerRadius={70}
                            fill="#8884d8"
                            label
                          >
                            {data.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                        </PieChart>
                        <div
                          className="d-flex justify-content-center"
                          style={{ gap: ".8em" }}
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className="mr-1"
                              style={{
                                backgroundColor: "var(--green)",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <p style={{ fontSize: "10px", margin: "0" }}>
                              Positive
                            </p>
                          </div>

                          <div className="d-flex align-items-center">
                            <div
                              className="mr-1"
                              style={{
                                backgroundColor: "var(--red)",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <p style={{ fontSize: "10px", margin: "0" }}>
                              Negative
                            </p>
                          </div>

                          <div className="d-flex align-items-center">
                            <div
                              className="mr-1"
                              style={{
                                backgroundColor: "var(--yellow)",
                                width: "8px",
                                height: "8px",
                                borderRadius: "50%",
                              }}
                            ></div>
                            <p style={{ fontSize: "10px", margin: "0" }}>
                              Neutral
                            </p>
                          </div>
                        </div>
                      </div>
                    </Body>
                  </CardCustom>

                  <CardCustom className="mt-3" style={{ width: "300px" }}>
                    <Body>
                      <h3 className="bold-small-paragraph">
                        Compliments & Complaints
                      </h3>
                      <RadarChart
                        cx="50%"
                        cy="50%"
                        outerRadius={70}
                        width={260}
                        height={250}
                        data={projects.data.complaint_compliment}
                      >
                        <PolarGrid />
                        <PolarAngleAxis dataKey="subject" />
                        <PolarRadiusAxis
                          angle={30}
                          domain={[
                            0,
                            projects.data.complaint_compliment.fullMark,
                          ]}
                        />
                        <Radar
                          name="Complaints"
                          dataKey="complaint"
                          stroke="#e55767"
                          fill="#e55767"
                          fillOpacity={0.6}
                        />
                        <Radar
                          name="Compliments"
                          dataKey="comliment"
                          stroke="#6fcf99"
                          fill="#6fcf99"
                          fillOpacity={0.6}
                        />
                        <Legend />
                      </RadarChart>
                      <div
                        className="d-flex justify-content-center"
                        style={{ gap: ".8em" }}
                      >
                        <div className="d-flex align-items-center">
                          <div
                            className="mr-1"
                            style={{
                              backgroundColor: "var(--green)",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                          <p style={{ fontSize: "10px", margin: "0" }}>
                            Compliments
                          </p>
                        </div>

                        <div className="d-flex align-items-center">
                          <div
                            className="mr-1"
                            style={{
                              backgroundColor: "var(--red)",
                              width: "8px",
                              height: "8px",
                              borderRadius: "50%",
                            }}
                          ></div>
                          <p style={{ fontSize: "10px", margin: "0" }}>
                          Complaints
                          </p>
                        </div>
                      </div>
                    </Body>
                  </CardCustom>

                  <CardCustom
                    className=" mt-3 "
                    style={{
                      width: "300px",
                    }}
                    id="second-cardCustom-live-demo"
                  >
                    <Body className="d-flex flex-column justify-content-around">
                      <div className="comment-wrapper d-flex flex-column">
                        <TagCloud
                          minSize={15}
                          maxSize={20}
                          style={{ width: "100%" }}
                          tags={projects.data.word_frequencies}
                          rotate={90}
                          className="simple-cloud"
                        />
                      </div>

                      <div
                        className="d-flex flex-column justify-content-around"
                        style={{ margin: "auto 0" }}
                      >
                        <div></div>
                      </div>
                    </Body>
                  </CardCustom>
                </div>
              </div>
              <br></br>
            </div>
          );
        })}

      <div clasmsNae="d-flex mb-3"></div>

      <div>
        <br></br>
      </div>

      <div className="mt-5">
        <DangerCard>
          <Body
            className="d-flex align-items-center justify-content-around"
            style={{ gap: "3em" }}
          >
            <div>
              <p className="bold-small-paragraph" style={{ margin: "0" }}>
                Delete this dashboard
              </p>
              <p>
                Once you delete a dashboard, there is no going back. Please be
                certain.
              </p>
            </div>
            <Button variant="danger" onClick={() => setShowModal(true)}>
              Delete Dashboard
            </Button>
          </Body>
        </DangerCard>
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          Are you sure you want to continue to deletion process?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ProjectDelete} variant="danger">
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </ul>
  );
};

export default function Dashtry() {
  const url = window.location.href;
  const currentProject = url.split("/project-board/")[1].split("%20").join(" ");
  const [project, setProject] = useState([]);

  useEffect(() => {
    const userData = async () => {
      const usersProjects = await db
        .collection("Projects")
        .where("projectID", "==", currentProject)
        .get();
      setProject(
        usersProjects.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userData();
  }, []);

  return (
    <div className="d-flex flex-column">
      <Helmet>
        <title>Brieferr | Dashboard</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      {project &&
        project.map((thisproject) => {
          return (
            <div
              className="page-container"
              style={{
                position: "relative",
                padding: "2em",
                width: "100%",
              }}
            >
              <div
                className="page-wrapper"
                style={{
                  maxWidth: "900px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "3em",
                  margin: "0 auto",
                }}
              >
                {thisproject.data ? (
                  <div id="divToPrint">
                    <Dashboard></Dashboard>
                  </div>
                ) : (
                  <CreatingProject project={project}></CreatingProject>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
}
