import React, { useState, useEffect } from 'react';
import app from '../../../firebase'
import { useAuth } from '../../../context/AuthContext';
import {CopyToClipboard} from 'react-copy-to-clipboard'
const db = app.firestore()

function AccountInfo() {
  
    const { currentUser } = useAuth();
    const [copy, setCopy] = useState(false);
    const [show, setShow] = useState(false);

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setShow(!show);
      };
        return (
        
        <div className="d-flex align-items-center w-100  pt-5 "
        style={{gap:".5em"}}>
               Your API Key: <input size="400px" value={currentUser.uid} type={show ? "text" : "password"}
               style={{width:"43%"}}></input>
               <button  style={{
                  backgroundColor:"var(--soft-purple)", 
                  width:"50px",
                  height: "50px",
                  borderRadius:"50%",
                  border:"none"
                }}variant="success" onClick={togglePassword}><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-eye" viewBox="0 0 15 17">
  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
</svg></button>
               <CopyToClipboard text={currentUser.uid}
               onCopy={() => setCopy(true)}>
                <button style={{
                  backgroundColor:"var(--soft-orange)", 
                  width:"50px",
                  height: "50px",
                  borderRadius:"50%",
                  border:"none"
                }} variant="success"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 15 20">
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                </svg> </button>
                </CopyToClipboard>
         
                {copy ? <b>Copied!</b> : <div></div>}
               
        
        </div>
    );
}

export default AccountInfo;