import React from 'react';

function DeliveryAndReturn() {
    return (
        <div>
            <h1>Delivery And Return Agreement</h1>
            <h3 id="item-1">ITEM-1</h3>
<p>PARTIES TO THE AGREEMENT</p>
<p>SELLER: AIX Artificial Intelligence Technologies Inc.</p>
<p>BUYER: Users who purchase the service from the <a href="http://www.brieferr.com/">www.brieferr.com</a> site of AIX Artificial Intelligence Technologies.</p>
<h3 id="article-2">ARTICLE-2</h3>
<p>The subject of this contract is about the law numbered 4077 on the protection of consumers and the applicable principles and procedures of distance contracts regarding the delivery and return of the goods/services that have the qualifications mentioned in the contract and whose sales price is also specified in the contract, from the buyer&#39;s website <a href="http://www.brieferr.com/">www.brieferr.com</a>. It is the determination of the rights and obligations of the parties in accordance with the provisions of the regulation. The buyer, the basic characteristics of the goods / services subject to the sale, the way of payment of sales prices, delivery conditions, etc. They accept and declare that they know all preliminary information and the right of &quot;withdrawal&quot; regarding the goods/services subject to sale, confirm this preliminary information, and then order the goods/services according to the provisions of this contract.</p>
<h3 id="item-3">ITEM-3</h3>
<p><strong>Product Delivery and Return Conditions:</strong> Applicable only to our customers who purchase service from <a href="http://www.brieferr.com/">www.brieferr.com</a>.</p>
<p><strong>Product Delivery</strong></p>
<p>The service package you have purchased through our site will be instantly credited to your account. If there are any requests and development requests regarding the service you have received, these will be translated into a contract by our company with a protocol detailing the demands of the parties. From the moment the contract is signed, or after the contract and after the special offer; Job acceptance, payment, and all transactions related to the site will be determined according to the terms and conditions of the sales contract. All correspondence made by mail will be included in the protocol between the parties at the point where necessary.</p>
<h3 id="item-4">ITEM-4</h3>
<p>RETURN TERMS</p>
<p>Our company protects consumer rights and prioritizes after-sales customer satisfaction. Any dissatisfaction with the products and services you have purchased, any software, and service-related problems are carefully evaluated and resolved as soon as possible.</p>
<p>Below, you can find the conditions necessary for us to serve you better and faster regarding the return of the purchased product or service; If any of these are missing, product returns are not accepted. In cases where the product or service you purchased does not have the features and qualifications specified on <a href="http://www.brieferr.com/">www.brieferr.com</a>, a refund can be made.</p>

        </div>
    );
}

export default DeliveryAndReturn;