import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert, Col, Row } from "react-bootstrap";
import { useAuth } from "../../../context/AuthContext";
import { Link, useHistory } from "react-router-dom";
import app from "../../../firebase";
import { Container } from "@material-ui/core";

const db = app.firestore();

export default function UpdateProfile() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [success, setSuccess] = useState();
  const [info, setInfo] = useState([]);
  const name =
    document.getElementById("name") && document.getElementById("name").value;

  const userName = {
    name: name,
    userID: currentUser.uid,
  };

  useEffect(() => {
    const userName = async () => {
      const Users = await db
        .collection("Users")
        .where("userID", "==", currentUser.uid)
        .get();
      setInfo(
        Users.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    userName();
  }, []);

  const onSubmit = (e) => {
    e.preventDefault(); // prevent page refreshing

    try {
      setError("");
      setLoading(true);
      db.collection("Users")
        .doc(currentUser.uid)
        .set({
          name:
            document.getElementById("user-name") &&
            document.getElementById("user-name").value,
          companySize:
            document.getElementById("employee-count") &&
            document.getElementById("employee-count").value,
          endustry:
            document.getElementById("endustry") &&
            document.getElementById("endustry").value,
          companyName:
            document.getElementById("company-name") &&
            document.getElementById("company-name").value,
          userID: currentUser.uid,
          email: currentUser.email,
        })
        .then(() => {
          window.location.reload();
        });
    } catch (error) {
      setError("Failed to get user information. Please try again.");
    }

    setLoading(false);
  };

  return (
    <Container className="d-flex align-items-center w-100  pt-5 ">
      <div className=" w-100">
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}

          {info &&
            info.map((user) => {
              return (
                <Form
                  onSubmit={onSubmit}
                  className="d-flex flex-column align-items-center"
                >
                  {/* name and last name */}
                  <Form.Group className="w-50">
                    <input
                      className="settings-input"
                      id="user-name"
                      placeholder={user.name}
                      type="text"
                      required
                    />
                  </Form.Group>

                  {/* company name */}
                  <Form.Group className="w-50">
                    <input
                      className="settings-input"
                      id="company-name"
                      placeholder={user.companyName}
                      type="text"
                      required
                    />
                  </Form.Group>
                  {/* Company Size */}
                  <Row className="w-50">
                    <Form.Group as={Col} className="d-flex flex-column w-100">
                      <Form.Label> Company Size </Form.Label>
                      <select
                        id="employee-count"
                        a
                        class="form-select"
                        aria-label="Default select example"
                        required
                      >
                        <option value="1-9" selected>
                          1 to 9 employees
                        </option>
                        <option value="10-49">10 to 49 employees</option>
                        <option value="50-249">50 to 249 employees</option>
                        <option value="250+">250+ employees</option>
                      </select>
                    </Form.Group>

                    <Form.Group as={Col} className="d-flex flex-column w-100">
                      <Form.Label> Sector </Form.Label>
                      <select
                        id="endustry"
                        class="form-select"
                        aria-label="Default select example"
                        required
                      >
                        <option value="Arts Entertainment" selected>
                          Arts and Entertainment
                        </option>
                        <option value="Consumer Discretionary">
                          Consumer Discretionary
                        </option>
                        <option value="Consumer Staples">
                          Consumer Staples
                        </option>
                        <option value="Educational">
                          Educational Services
                        </option>
                        <option>Energy</option>
                        <option value="Financials">Financials</option>
                        <option value="Food Service">Food Service</option>
                        <option value="Health Care">Health Care</option>
                        <option value="Industrials">Industrials</option>
                        <option value="Information Technology">
                          Information Technology
                        </option>
                        <option value="materials">Materials</option>
                        <option value="Telecommunication Services">
                          Telecommunication Services
                        </option>
                        <option value="Utilities">Utilities</option>
                      </select>
                    </Form.Group>
                  </Row>
                  <button
                    disabled={loading}
                    className="CSVImporter_TextButton"
                    type="submit"
                  >
                    Update Profile
                  </button>
                </Form>
              );
            })}
        </Card.Body>

        <div className="w-100  text-center mt-2">
          <Link to="/">Cancel</Link>
        </div>
      </div>
    </Container>
  );
}
