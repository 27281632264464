import React from 'react';
import { Container } from 'react-bootstrap';
import ImageAndText from '../../components/ImageAndText';
import Navigation from '../../elements/Navbar';
import landing from '../../styles/images/developers.png'
import apps from '../../styles/images/app.png'
import cc from '../../styles/images/automations.png'
import metrics from '../../styles/images/api.png'
import Footer from '../../../components/page/components/partials/Footer'
import ForCampaign from './call to action footer/ForCampaign';
import arrow from '../../styles/icons/chevron.svg'

function ForDevelopers(props) {
    return (
        <div>
            <Navigation />

            <Container>
                <div 
                style={{maxWidth:"900px"}}
                className='pt-5 text-center m-auto'>
                    <h1>Helping campaign managers adapt their <span className='highlighted'>platform</span></h1>
                    <p className='pt-4 pb-4 banner-paragraph'> 
                    Discover what matters to your voters
                    </p>
                    <div className='w-25 pb-4 m-auto' id='about-last'>
                        <a  className='custom-btn mt-3' href='/signup'>Start now <img src={arrow}></img></a>
                    </div>
                    <div className='w-100'>
                        <img 
                        style={{width:"100%"}}
                        src={landing}></img>
                    </div>
                </div>
            </Container>
            <div className='gray-bg mt-5 pt-5 pb-5'>
                <Container>
                    <ImageAndText 
                    heading="A platform that increases campaign success by generating highly actionable insights"
                    smallText="AUTOMATE"
                    image={cc}
                    flex="normal" />
                </Container>
            </div>
            <Container className='mt-5 mb-5 pt-5 pb-5'>
                    <ImageAndText 
                    heading="Plan campaign promises, prioritize messages, and reach wider"
                    smallText="TEXT ANALYTICS API"
                    image={metrics}
                    flex="reverse" />
            </Container>
            <div className='gray-bg mt-5 pt-5 pb-5'>
                <Container>
                    <ImageAndText 
                    heading="Use with any application"
                    smallText="FLEXIBILITY"
                    paragraph="Create up-to-date dashboards without having to change the current app you use to get responses."
                    image={apps}
                    flex="normal" />
                </Container>
            </div>
            <ForCampaign />
            <Footer />
        </div>
    );
}

export default ForDevelopers;