import React from 'react'
import {Button }from 'react-bootstrap'
import { Link } from 'react-router-dom'
export default function NotFound() {
    return (
        <div className="not-found-container d-flex flex-column align-items-center justify-content-center">
           <h1 className="highlighted-word">404</h1>
           <h5>Page not Found</h5>
           <h6> This is not web page you are looking for. </h6>
           <Link className="highlited-word" to="/">Back to Home</Link>
        </div>
    )
}
