import React, {useState, useEffect} from 'react'
import { Container, Card, Form, Button, Row,Col , Alert} from 'react-bootstrap'
import app from '../../firebase'
import { useAuth } from "../../context/AuthContext"
import { useHistory } from 'react-router-dom'
import{ init } from 'emailjs-com';
import emailjs from 'emailjs-com';
import { PrimaryButtonVariant } from '../../design/components/PrimaryButton'
import { TextInput} from '../../design/components/TextInput'

init("user_VunEIO9kDbmWZSsFxLXC0");
const db = app.firestore()

function AppsumoUserInfo() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const history = useHistory();

  const onSubmit = (e) => {
    e.preventDefault(); // prevent page refreshing

    try {
      setError("");
      setLoading(true);
      db.collection("Users")
        .doc(currentUser.uid)
        .set({
          name:
            document.getElementById("user-name") &&
            document.getElementById("user-name").value,
          companySize:
            document.getElementById("employee-count") &&
            document.getElementById("employee-count").value,
          endustry:
            document.getElementById("endustry") &&
            document.getElementById("endustry").value,
          companyName:
            document.getElementById("company-name") &&
            document.getElementById("company-name").value,
          userID: currentUser.uid,
          email: currentUser.email,
          plan: "appsumo",
          max_dashboard_count: 1500,
        });

      history.push("/plan-billing");

      if (currentUser) {
        var emailParams = {
          email: currentUser.email,
        };
        emailjs.send("service_g4rcroj", "template_ecjeslb", emailParams).then(
          function (response) {},
          function (error) {}
        );
      } else {
      }
    } catch (error) {
      setError("Failed to get user information. Please try again.");
    }

    setLoading(false);
  };

  return (
    <div>
      {" "}
      <Container className="d-flex align-items-center  justify-content-center signUpIn-container">
        <div className=" w-100">
          <Card.Body className=" w-100">
            <Form
              id="user-info-form"
              className="d-flex flex-column align-items-center"
              onSubmit={onSubmit}
            >
              <Alert className="w-100 " variant="success">
                {" "}
                Your account created successfuly. Please enter additional
                information.
              </Alert>

              {/* name and last name */}
              <Form.Group className="w-100">
                <TextInput placeholder="Name and Last Name" id="user-name" />
              </Form.Group>

              {/* company name */}
              <Form.Group className="w-100">
                <TextInput placeholder="Company Name" id="company-name" />
              </Form.Group>

              {/* Company Size */}
              <Row className="w-100">
                <Form.Group as={Col} className="d-flex flex-column w-100">
                  <Form.Label> Company Size </Form.Label>
                  <select
                    id="employee-count"
                    class="form-select"
                    aria-label="Default select example"
                    required
                  >
                    <option selected value="1-9">
                      1 to 9 employees
                    </option>
                    <option value="10-49">10 to 49 employees</option>
                    <option value="50-249">50 to 249 employees</option>
                    <option value="250+">250+ employees</option>
                  </select>
                </Form.Group>

                <Form.Group as={Col} className="d-flex flex-column w-100">
                  <Form.Label> Sector </Form.Label>
                  <select
                    id="endustry"
                    class="form-select"
                    aria-label="Default select example"
                    required
                  >
                    <option value="Arts Entertainment">
                      Arts and Entertainment
                    </option>
                    <option value="Consumer Discretionary">
                      Consumer Discretionary
                    </option>
                    <option value="Consumer Staples">Consumer Staples</option>
                    <option value="Educational">Educational Services</option>
                    <option selected>Energy</option>
                    <option value="Financials">Financials</option>
                    <option value="Food Service">Food Service</option>
                    <option value="Health Care">Health Care</option>
                    <option value="Industrials">Industrials</option>
                    <option value="Information Technology">
                      Information Technology
                    </option>
                    <option value="materials">Materials</option>
                    <option value="Telecommunication Services">
                      Telecommunication Services
                    </option>
                    <option value="Utilities">Utilities</option>
                  </select>
                </Form.Group>
              </Row>
              <PrimaryButtonVariant
                text="Start Using Brieferr"
                disabled={loading}
                className="w-100 btn-success"
                type="submit"
              />
            </Form>
          </Card.Body>
        </div>
      </Container>
    </div>
  );
}

export default AppsumoUserInfo;
