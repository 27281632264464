import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    width: 100%;
    padding: .5em 1em;
    border-radius: 10px;
    border: 1px solid var(--dark-gray);
    &::-webkit-input-placeholder {
    font-family: 'Fira Sans', sans-serif;
  }
`
function TextInput(props) {
    return (
        <div className={props.class}>
            <Input value={props.val}
             ref={props.referance} 
             id={props.id} type='text' 
             placeholder={props.placeholder} 
             onChange={props.change}
             required></Input>
        </div>
    );
}
function PasswordInput(props){
    return (
        <div className={props.class}>
            <Input id={props.id} ref={props.referance} type='password' placeholder={props.placeholder} required></Input>
        </div>
    );
}


export {TextInput, PasswordInput};