import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Extracting entities",
  "Generating highlighted sentences",
  "Generating summary",
  "Analyzing sentiment",
  "Finding compliments and complaints",
];


export default function CustomizedSteppers(props) {
  const [activeStep, setActiveStep] = React.useState(0);

  React.useEffect(() => {
    if (props.step === "entities") {
      setActiveStep(0);
    }
    if (props.step === "highlights") {
      setActiveStep(1);
    }
    if (props.step === "summary") {
      setActiveStep(2);
    }
    if (props.step === "sentiment_analysis") {
      setActiveStep(3);
    }
    if (props.step === "compliment_complaint") {
      setActiveStep(4);
    }
    if (props.step === "finished") {
      setActiveStep(5);
    }
  });

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (  
            
            
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <div className="w-100 mt-5 d-flex align-items-center justify-content-center text-center">
          <button
            className="CSVImporter_TextButton"
            onClick={() => window.location.reload()}
          >
            Go to Dashboard
          </button>
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </Box>
  );
}
