import React from 'react';

function Terms(props) {
    return (
        <div>
            <h1>TERMS AND CONDITIONS OF USE</h1>
            <p> <strong>USER AGREEMENT</strong></p>
<p><strong>1. GENERAL PROVISIONS</strong></p>
<p><strong>1.1</strong> The terms and conditions contained in this Agreement summarize the rules and regulations regarding the use of the AIX Artificial Intelligence Technologies Inc. website at <a href="https://www.brieferr.com/">https://www.brieferr.com</a>. Law No. 6698 on the Protection of Personal Data (&quot;KVKK&quot;) In order to fulfill the obligation of illumination arising from m. 10. AIX Artificial Intelligence Technologies Inc. reserves the right to update the text of this statement on the Protection of Personal Data at any time within the framework of the changes that can be made in the current legislation.</p>
<p><strong>1.2.</strong> Before using our Website, read these &#39;terms and conditions of use carefully. It is assumed that every natural and legal person using this Website has accepted the following conditions.</p>
<p><strong>1.3</strong> The web pages on the Website and all pages linked to it are owned and operated by AIX Artificial Intelligence Technologies Inc. at <a href="https://www.brieferr.com/">https://www.brieferr.com</a>. By using and continuing to use the service on the Website, you (&#39;User&#39;) are subject to the following conditions while using all the services offered on the Site; You agree that you have the right, authority, and legal capacity to sign a contract in accordance with the laws to which you are bound and that you are over the age of 18, that you have read and understood this contract and that you are bound by the terms written in the contract.</p>
<p><strong>1.4</strong> THIS AGREEMENT HAS THE RIGHTS AND OBLIGATIONS OF THE PARTIES RELATED TO THE SUBJECT TO THE AGREEMENT, THE LOADS, AND THE PARTIES ACCEPT THIS AGREEMENT.</p>
<p><strong>2) DEFINITIONS</strong></p>
<p><strong>2.1</strong> &quot;User&quot; means the legal and/or natural persons who use the Website for any reason and/or receive service through the Website.</p>
<p><strong>2.2</strong> &quot;Agreement&quot; and/or &quot;Website Terms of Use&quot; means this &quot;User Agreement.&quot;</p>
<p><strong>2.3</strong> &quot;Website&quot; and/or &quot;Site&quot; means the Website <a href="https://www.brieferr.com/">https://www.brieferr.com</a>.</p>
<p><strong>3) INTELLECTUAL PROPERTY RIGHTS-LICENSE</strong></p>
<p><strong>3.1</strong> All proprietary or unregistered intellectual property rights such as title, business name, trademark, patent, logo, design, information, and method in this Web Site belong to the site operator and owner company or the person concerned and are under the protection of national and international law. Visiting this Site or utilizing the services on this Site does not grant any right to such intellectual property rights.</p>
<p><strong>3.2</strong> The information on the Site cannot be reproduced, published, copied, presented, and/or transferred in any way. The whole or part of the Site cannot be used on another website without permission.</p>
<p><strong>3.3</strong> AIX Artificial Intelligence Technologies Inc. and/or its licensors own the intellectual property rights to all materials on Brieferr. All intellectual property rights reserved. You can access it on Brieferr for your own personal use, subject to the restrictions set in these terms and conditions.</p>
<p>What you cannot do:</p>
<ul>
<li>Republish material from Brieferr</li>
<li>Selling, renting, or sublicensing materials from Brieferr</li>
<li>Reproduce, reproduce or copy materials in Brieferr</li>
<li>Redistribute content from Brieferr</li>
</ul>
<p><strong>3.4</strong> The software used in the design of these pages and the creation of the database belongs to AIX Artificial Intelligence Technologies Inc. It is strictly forbidden to copy or use the aforementioned software and to reverse engineer the software and technologies used.</p>
<p><strong>4) ABOUT COMMENTS ON THE SITE</strong></p>
<p><strong>4.1</strong> AIX Artificial Intelligence Technologies Inc. does not filter, edit, publish or review comments before they appear on the Website. Comments do not reflect the views and opinions of AIX Artificial Intelligence Technologies Inc., its agents, and/or affiliates. Comments reflect the views and opinions of the person posting their views and opinions. To the fullest extent permitted by applicable law, AIX Artificial Intelligence Technologies Inc. will not be liable for the Comments or any liability, damage, or expense incurred and/or resulting from any use and/or posting and/or appearance. AIX Artificial Intelligence Technologies Inc. of Comments on this Website reserves the right to monitor all Comments and to remove comments that may be deemed inappropriate, offensive, or cause a violation of these Terms and Conditions.</p>
<p><strong>4.2</strong> You warrant and represent that:</p>
<ul>
<li>You have the right to post Comments on our Website, and you have all necessary licenses and permissions to do so;</li>
<li>Comments do not infringe any intellectual property rights, including but not limited to the copyright, patent, or trademark of any third party;</li>
<li>Comments do not contain material that violates privacy, is defamatory, defamatory, offensive, indecent, or otherwise illegal.</li>
<li>Comments will not be used to promote or promote business or customs or to present business or illegal activities.</li>
<li>You hereby grant AIX Artificial Intelligence Technologies Inc. a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit your Comments in any form and in all forms, formats, or media.</li>
</ul>
<p><strong>5) BRIDGE TO CONTENT</strong></p>
<p><strong>5.1</strong> The following entities may connect to our Website without prior written consent:</p>
<ul>
<li>Government agencies;</li>
<li>Search engines;</li>
<li>news organizations;</li>
<li>Online directory distributors may link to our Website in the same way that other listed businesses hyperlink to their Websites;</li>
<li>System-wide accredited businesses, with the exception of requesting nonprofits, charitable malls, and charity fundraising groups that fail to create a hyperlink to our Website.</li>
</ul>
<p><strong>5.2</strong> We may consider and approve other connection requests from the following types of organizations:</p>
<ul>
<li>widely known sources of consumer and/or business information;</li>
<li><a href="http://dot.com/">dot.com</a> community sites;</li>
<li>associations or other groups representing charities;</li>
<li>online directory distributors;</li>
<li>internet portals;</li>
<li>accounting, legal, and consulting firms; and</li>
<li>educational institutions and trade associations.</li>
</ul>
<p><strong>5.3</strong> If such links do not cause us to appear unfavorable to ourselves or our accredited businesses, link requests from the above organizations may be approved if the organization does not have any negative records with us and considering the benefit of the bridge&#39;s visibility to us.</p>
<p><strong>5.4</strong> If you are one of the organizations listed above and are interested in linking to our Website, you should notify us by sending an e-mail to AIX Artificial Intelligence Technologies Inc. Please include your name, your organization&#39;s name, your contact information, and the URL of your Website, a list of URLs you intend to link to our Website, and a list of URLs you would like to have on our Site. Wait 2-3 weeks for a response.</p>
<p><strong>5.5</strong> Approved organizations <em>**</em>may hyperlink to our Website as follows:</p>
<ul>
<li>Using our corporate name; or</li>
<li>Using the uniform source locator that is linked; or</li>
<li>The use of any other description to which our Website is linked is meaningful within the context and format of the content on the linking party&#39;s Website.</li>
</ul>
<p><strong>5.6 The use of</strong> AIX Artificial Intelligence Technologies Inc.&#39;s logo or other artwork for linking without a trademark license agreement will not be permitted.</p>
<p><strong>6) iFrame</strong></p>
<p>You may not create frames around our Web Pages that alter the visual presentation or appearance of our Web Site in any way without our prior approval and written permission.</p>
<p><strong>7) COOKIES</strong></p>
<p>By accessing Brieferr, you agree to use cookies in accordance with AIX Artificial Intelligence Technologies Inc.&#39;s Privacy Policy. Most interactive websites use cookies to allow us to retrieve the user&#39;s details for each visit. Cookies are used by our Website to provide the functionality of certain areas to facilitate the work of visitors to our Website. Some of our affiliate/advertising partners may also use cookies.</p>
<p><strong>8) ABOUT LINKS ON OUR WEBSITE</strong></p>
<p><strong>8.1</strong> We reserve the right to request that you remove all links or a particular link to our Website. You agree to remove all links to our Website upon request immediately. We also reserve the right to change these terms and conditions and the link policy at any time.</p>
<p><strong>8.2 If for</strong> any reason you find an offensive link on our Website, you may contact and inform us at any time. We will consider requests to remove links but are under no obligation to respond directly to you.</p>
<p><strong>8.3</strong> We do not guarantee the accuracy, completeness, or accuracy of the information on this Website; nor do we undertake to ensure that the Website remains available or that the material on the Website is kept up to date.</p>
<p><strong>9) PRIVACY</strong></p>
<p><strong>9.1 The</strong> Company will not disclose personal information transmitted by users through the Site to third parties. This personal information; contains all kinds of other information to identify the user, such as person name-surname, address, telephone number, mobile phone, e-mail address, and will be referred to as &#39;Confidential Information&#39; for short.</p>
<p><strong>9.2 The</strong> user can only use promotions, advertisements, campaigns, promotions, announcements, etc. The Company accepts and declares that it consents to the sharing of its communication, portfolio status, and demographic information with its subsidiaries or group companies to which it is affiliated, limited to its use within the scope of marketing activities. This personal information can be used to determine the customer profile within the Company, to offer promotions and campaigns suitable for the customer profile, and to carry out statistical studies. These transactions do not constitute a violation of the terms of the Privacy Policy.</p>
<p><strong>9.3</strong> Confidential Information may only be disclosed to official authorities if this information is duly requested by official authorities and when disclosure to official authorities is obligatory in accordance with the provisions of the applicable mandatory legislation.</p>
<p><strong>10) NO WARRANTY</strong></p>
<p>This contract clause shall apply to the maximum extent permitted by applicable law. Services offered by the Company are provided on an &quot;as is&quot; and &quot;as available&quot; basis and are expressed or implied with respect to the services or application (including any information contained therein), including any implied warranties of merchantability fitness for a particular purpose, or non-infringement, makes no warranty of any kind, statutory or otherwise.</p>
<p><strong>11) WEBSITE CONTENT LIMITATIONS OF LIABILITY AND REGISTRATION-SECURITY ISSUE</strong></p>
<p><strong>11.1 The</strong> user must provide accurate, complete, and up-to-date registration information. Otherwise, this Agreement will be deemed to have been violated, and the account may be closed without informing the user. The user is responsible for the password and account security on the Site and third-party sites. Otherwise, the Company cannot be held responsible for data loss and security breaches or damage to hardware and devices.</p>
<p><strong>11.2 To</strong> the extent legally permitted under applicable law,</p>
<p>AIX Artificial Intelligence Technologies Inc. does not undertake that the Website will be error-free, uninterrupted, and secure or that the use of the Website or any content, search, or link on it will provide specific results.</p>
<p>AIX Artificial Intelligence Technologies Inc. cannot guarantee that any file downloaded from the Website will not contain viruses or other dirty or destructive features.</p>
<p><strong>11.3</strong> AIX Artificial Intelligence Technologies Inc. is not responsible for any data loss arising from the operation of the Website or the implementation of its conditions.</p>
<p><strong>11.4 We</strong> cannot be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims made on your Website. There must be no link on any Website that could be construed as libelous, obscene, or criminal or that infringes, otherwise violates, or advocates infringement or other violations of any third party right.</p>
<p><strong>12) FORCE MAJEURE</strong></p>
<p>not under the control of the parties; If the obligations arising from the contract become unfulfilled by the parties due to reasons such as natural disasters, fire, explosions, civil wars, wars, uprisings, public movements, declaration of mobilization, strikes, lockouts, and epidemics, infrastructure and internet failures, power cuts, the parties are not responsible for this.  During this period, the rights and obligations of the parties arising from this contract are suspended.</p>
<p><strong>13) COMPLETENESS AND APPLICABILITY OF THE AGREEMENT</strong></p>
<p>If one of the terms of this contract becomes partially or completely invalid, the rest of the contract continues to be valid.</p>
<p><strong>14) CHANGES TO THE CONTRACT</strong></p>
<p>The Company may partially or completely change the services offered on the Site and the terms of this contract. Changes will be effective from the date of publication on the Site. It is the user&#39;s responsibility to keep track of changes. The user is deemed to have accepted these changes by continuing to benefit from the services offered.</p>
<p><strong>14) NOTICE</strong></p>
<p>All notifications to be sent to the parties related to this Agreement will be made through the known e-mail address of the Company and the e-mail address specified by the user in the membership form. The user agrees that the address he/she has specified while signing up is a valid notification address, that he/she will notify the other party in writing within five days in case of change; otherwise, the notifications to this address will be deemed valid.</p>
<p><strong>15) EVIDENCE AGREEMENT</strong></p>
<p>In all kinds of disputes that may arise between the parties for the transactions related to this contract, the books, records and documents of the parties, computer records, and fax records will be accepted as evidence in accordance with the Law of Civil Procedure No. 6100, and the user agrees that he will not object to these records.</p>
<p><strong>16) RESOLUTION OF DISPUTES</strong></p>
<p><strong>16.1</strong> This contract is subject to the laws of the Republic of Turkey.</p>
<p><strong>16.2 The</strong> parties try to settle any disputes that may arise from the application and interpretation of this contract amicably among themselves. For the settlement of disputes that cannot be settled amicably, a solution is sought first in the presence of an independent and impartial Mediator; otherwise, they accept and declare that Istanbul Courthouse Courts and Enforcement Offices are exclusively authorized.</p>
<p><strong>17) ENFORCEMENT</strong></p>
<p>This Agreement will remain in effect as long as the Website continues to be used and a new agreement regarding terms of use is not presented to the users by AIX Artificial Intelligence Technologies Inc.</p>

        </div>
    );
}

export default Terms;