import React, { useState, useRef, useEffect } from "react";
import { Container, Card, Form, Alert } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { TextInput, PasswordInput } from "../components/TextInput";
import {
  PrimaryButtonVariant,
  SecondaryButton,
} from "../components/PrimaryButton";
import { useAuth } from "../../context/AuthContext";
import app from "../../firebase";
import "firebase/firestore";
import firebase from "firebase";
import axios from "axios";
import {Link} from 'react-router-dom'
function AppSumoLogin() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const url = window.location.href;
  const id = url.split("/appsumo/appsumo-login/")[1];
  const db = app.firestore();
  const [resdata, setresData] = useState();

  useEffect(() => {
    async function onClick() {
      const response = await axios({
        method: "post",
        url: "https://europe-west3-auth-developement-9e9a6.cloudfunctions.net/addMessage",
        data: { appsumo_id: id, auth: "fB2jcDxbPeNhrUnT" },
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = response.data;
      setresData(data);
      console.log(data);
    }
    onClick();
  });

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      window.location.replace("/appsumo-user-information");
    } catch (error) {
      setError("Failed to Log In");
    }
    setLoading(false);
  } return (
    <div>
     <Container className="d-flex signUpIn-container align-items-center justify-content-center h-100vw">
      <Helmet>
        <title>Brieferr | Log In</title>
        <meta name="description" content="Helmet application" />
      </Helmet>
      <div className=" w-100">
        <Card.Body>
        <h2 className=" mb-4">
              Activate your{" "}
              <span style={{ color: "var(--purple)" }}>Brieferr account</span>
            </h2>
            <p
              className="banner-paragraph"
              style={{ color: "var(--dark-gray)" }}
            >
              One last step to activate your account from our AppSumo campaign!
            </p>
          {error && <Alert variant="danger">{error}</Alert>}

          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column align-items-center"
          >
            <Form.Group id="email" className="w-100" id="form-group">
            <input
                className="appsumo-email"
                placeholder="Static Mail"
                ref={emailRef}
                type="email"
                value={resdata}
                readOnly
              ></input>
            </Form.Group>
            <Form.Group id="password" className="w-100" id="form-group">
              <PasswordInput
                placeholder="Password"
                type="password"
                class="w-100"
                referance={passwordRef}
                required
              />{" "}
            </Form.Group>

            <PrimaryButtonVariant
              disabled={loading}
              text="Activate Account"
            ></PrimaryButtonVariant>
          </Form>
          <small
            style={{ color: "#707070" }}
            className="w-100 text-left pb-3 pt-3"
          >
            By clicking Activate Account button, you agree to our{" "}
            <a href="/terms" target="_blank">
              Terms and Conditions
            </a>{" "}
            and that you read to our{" "}
            <a href="/data-policy" target="_blank">
              Data Use Policy
            </a>
            , including Cookie Use.
          </small>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </Card.Body>
      </div>
    </Container>
    </div>
  );
}

export default AppSumoLogin;
