import React from 'react'
import Navbar from '../../design/elements/Navbar'
import Landing from './components/Landing'
import { Container } from 'react-bootstrap'
import {Helmet} from "react-helmet";
import CookieAlert from './components/partials/CookieAlert'
import ThreeSteps from '../../design/elements/ThreeSteps'
import Features from '../../design/elements/Features'
import FormApplications from '../../design/elements/FormApplications'
import FlexPlans from '../../design/elements/FlexPlans';
import Footer from './components/partials/Footer';
import Email from './components/partials/Email'
import Tawk from './components/partials/Tawk';

export default function Home() {

    return (
        
        <div>
            <Helmet>
        <title>Brieferr | Form Analysis Made Easy</title>
        <meta name="description" content="Helmet application" />
    </Helmet>
            
            <Navbar></Navbar>
           
            <Landing />
            <Email />
            <ThreeSteps />
           <Features />
           <FormApplications />
           <FlexPlans />
           <Email />
           <Footer />
            <div style={{width:"100%", height:"100%"}}>
           <CookieAlert/>
           </div>
           
         
        </div>
    )
}
