import React from 'react';
import Dashtry from './Dashtry';
import Sidebar from '../../../../design/elements/Sidebar';
import MobileSidebar from '../../../../design/elements/MobileSidebar';

function InsideDash(props) {
    return (
        <div className='d-flex w-100 align-items-evenly'>
            <Sidebar />
            <MobileSidebar />
            <Dashtry />
        </div>
    );
}

export default InsideDash;